footer {
  display: block;
  z-index: 2;
  @include mq(992) {
    margin-top: 110px;
  }
  position: relative;
  img {
    width: 100%;
    height: 140px;
    margin-bottom: -5px;
    object-fit: cover;
    z-index: -5px;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    font-weight: 300;
    font-size: 14px;
    text-align: center;
    color: #fff;
    background-color: #17513d;
    width: 100%;
    height: 40px;
    z-index: 1;
    &:hover {
      color: white;
    }
  }

  &.footer__fixed {
    padding-bottom: 93px;
    @include mq(450) {
      padding-bottom: 135px;
    }
  }
}
