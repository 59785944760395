//style for gmap popup

@keyframes shine{
  from {
    opacity: 0;
    left: 0%;
  }

  50% {
    opacity: 1;
  }
  to {
    opacity: 0;
    left: 100%;
  }
}

@keyframes fade-in{
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out{
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes filtering{
  from {
    opacity: 1;
    height: 54.49px;
  }

  50% {
    opacity: 0.4;
  }
  to {
    opacity: 0;
    height: 0;
  }
}

.gm-style-iw-t
{
  bottom: 75px !important;
}


.map__container {
  position: relative;
  .filter__mobile {
    position: fixed;
    right: 20px;
    bottom: 20px;
    display: none;
    @include mq(992) {
      display: block;
    }

  }

  #map img[src*="googleusercontent.com"] {
    opacity: 0.8;
  }
  

  .map__filter__active {
    top: 0 !important;
  }

  .map__filter {
    position: absolute;
    right: 20px;
    top: 70px;
    width: 300px;
    overflow-y: auto;
    transition: all 0.4s;
    @include mq(992) {
      position: fixed;
      right: 0;
      bottom: 0;
      top: 100%;
      transition: all 0.4s;
      background: #fff;
      z-index: 1000000;
      width: 100%;
    }
    .form-check-input:checked {
      background-color: #17513D;
      border-color: #17513D;
  }
    .filter__form {
      position: relative;
      background: #fff;
      padding-bottom: 18px;
      overflow-y: auto;
      margin-top: 30px;
      .reset__form {
        position: absolute;
        right: 24px;
        top: 90px;
        cursor: pointer;

        font-weight: normal;
        font-size: 16px;
        transition: all 0.4s;
        color: #CACACA;
           border-bottom: 1px dashed #CACACA;
            
      }
      .reset__form__active{
        color: #0080ff;
        border-bottom: 1px dashed #0080ff;
      }
      h4 {
        padding: 16px 24px;
        background: #009f9d;
        border-bottom: 1px solid #eaeaea;
        margin-bottom: 32px;
        color: #fff;
        transition: all 0.4s;
        font-size: 20px;

      }

      

      .filter__item {
        padding: 0 24px;
        margin-bottom: 26px;
        h5 {
          transition: all 0.4s;
          margin-top: 20px;
          margin-left: -12px;
          font-weight: 500;
          font-size: 22px;
          color: #00152f;
          margin-bottom: 16px;
        }

        label {
          font-size: 16px;
          transition: all 0.4s;
          color: #00152f;
        }
      }

    }
    .link__to__image__identification {
      margin-top: 12px;
      padding: 18px 0px;

      font-size: 16px;
      text-align: center;
      color: #fff;

      display: block;
      border-radius: 6px;
      background: #ed5e5e;
      box-shadow: 0px 3px 11px rgba(0, 0, 0, 0.26);
    }

    .link__to__feedback {
      margin-top: 6px;
      padding: 18px 0px;

      font-size: 18px;
      text-align: center;
      color: rgb(29, 2, 2);

      display: block;
      border-radius: 6px;
      background: #fff;
      box-shadow: 0px 3px 11px rgba(0, 0, 0, 0.26);
    }
  }
  .warm__night ,  .cold__night{
      background: #031224 !important;
      color: #fff;
      h4{
        background: #FFA000 !important;
      }
      h5,label{
        color: #fff !important;
      }
      .form-check-input:checked {
        background-color: #7FADE4 !important;
        border-color: #7FADE4 !important;
    }
     .reset__form__active {
      
      color: #fff !important;
      border-bottom: 1px dashed #fff !important;
    }
    
  }
  .cold__night{
    h4{
      background: #0080FF !important;
    }
  }
  .warm__day{
    h4{
      background: #FFA000 !important;
    }
  }
 
  .cold__day{

  }
}


.aside__modal {
  position: fixed;
  left: -100%;
  top: 0;
  z-index: 99999999;
  width: 407px;
  @include mq(992) {
    width: 100vw;
  }
  height: 100vh;
  background: #fff;
  box-shadow: 3px 0px 19px rgba(0, 0, 0, 0.16);
  transition: all 0.4s;
  padding-bottom: 16px;
  @include mq(992) {
    left: -150%;
  }
  .main__content {
    height: 100%;
    overflow-y: auto;
  }
  h4 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 24px;
    font-weight: bold;
    font-size: 26px;

    color: #00152f;
  }
}
.aside__modal__active {
  left: 0;
}
.hospital__modal {
  .content {
    padding: 0 24px;
    img {
      width: 100%;
      border-radius: 10px;
      margin-bottom: 30px;
    }
    ul {
      li {
        .title {
          font-weight: 500;
          font-size: 18px;
          color: #848484;
          display: inline-flex;
          align-items: center;
          padding-right: 15px;
          svg {
            margin-right: 5px;
          }
        }

        font-weight: normal;
        font-size: 19px;

        color: #4d4d4d;
        margin-bottom: 30px;
      }
    }
    .route__to__hospital {
      margin-top: 80px;
      border-radius: 10px;
      background: #fff;
      border: 2px solid #5f65a6;
      text-align: center;
      padding: 16px 80px;

      display: inline-flex;
      align-items: center;
      font-weight: 500;
      font-size: 20px;

      color: #5f65a6;
      svg {
        margin-right: 8px;
      }
    }
  }
}


.point__modal {

  .filters__container{

    @include mq(992){
      display: none;
    }

    @include mq(1350){
      left: 50vw;
    }

    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 35px;
    top: 150px;
    width: 450px;
    height: 100vh;
    left: 450px;
    z-index: 10;
    position: absolute;
    transition: 0.6s all ease-in-out;
    pointer-events: none;

    .resetFilters{
      background-color: white;
      width: 150px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: none;
      border-radius: 8px;

      button{
        text-transform: uppercase;
        text-decoration: underline;
        color: #02363e;
        padding: 5px;
        font-weight: 900;
        font-size: 16px;
      }
    }

    > div{

      @include mq(1200){
        width: 72%;
      }

      width: 80%;
      background-color: white;
      height: 200px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    }

    .filter_box{

          $colors: (
            red: #DB2828,
            black: rgba(1,1,1,0.7),
            white: #F0f0f0,
        );

        padding:10px 25px;

        .filter__header{
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: row;
          flex-wrap: nowrap;

          h3{
            font-size: 32px;
            font-weight: 800;
            width: 80%;
            border-bottom: 0.8px solid rgba($dark-blue-400,0.6);
            margin-left: -5px;
        }

        button{
          position: relative;
          right: 5px;
          top: 0px;
          font-size: 16px;
          margin: 0;
         text-decoration: underline;
         font-weight: 600;
        }

        }

        .eyePicker
        {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          column-gap: 30px;
          padding-left:0px;
          width: 100%;
          height: 70%;

          input[type=radio]{
            width: 0;
            height: 0;
            position: fixed;
          }

          label {
            cursor: pointer;
            display: inline-block;
            background-color: #ddd;
            padding: 10px 20px;
            text-align: center;
            font-size: 16px;
            border: 2px solid #444;
            border-radius: 4px;
            margin-top: 15px;
        }

        input[type="radio"]:checked + label {
          background-color:#bfb;
          border-color: #4c4;
        }
        input[type="radio"]:focus + label {
          border: 2px dashed #444;
        }

        label:hover {
          background-color: #dfd;
        }

          .roundEye , .verticalEye{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 74px;
            height: 74px;
            background-color: white;
            border-radius: 50%;
            border: 3px solid black ;

            .pupil{  
            width: 52px;
            height: 52px;
            background-color: #111111;
            border-radius: 50%;
            }

            .pupil::before {
              content: ' ';
              width: 10px;
              height: 12px;
              transform: translateX(5px);
              background-color: white;
              display: block;
              border-radius: 50%;
            }

          }



          .verticalEye{
            background-color: white;
            border: 3px solid black;
             .pupil{
              display: flex;
              justify-content: center;
              align-items: center;
              width: 90%;
              height: 90%;
              background-color: white;
              border-radius: 50%;
              border: black 2px solid
              }
  
               .pupil::before {
                content: ' ';
                width: 100%;
                height: 100%;
                background-color: black;
                display: block;
                transform: translateX(0px);
                -webkit-clip-path: ellipse(9% 50% at 50% 50%);
                clip-path: ellipse(5% 50% at 50% 50%);
              }
          }
        }

        .patternPicker , .headPicker{
          height: 100%;
          width: 55%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 12px;
          margin-left: 0px;
          margin-top: 5px;

          .img-container{

            float: right;
            position: absolute;
            left:255px; 
            width: 130px;
            height: 130px;
            border: black 1px solid;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: -6px;
            
            img{
              
            }
          }

         
          .radioGroup{
            display: flex;
            align-items: center;
            width: 200px;

            input[type=radio]{
              padding: 0.5em;
              -webkit-appearance: none;
              outline: 0.1em solid #02363e ;
              outline-offset: 0.1em;
              width: 14px;
              height: 14px;
            }
  
            input[type=radio]:checked {
              display: inline-block;
              background-color: #02363e;
            }

            label{
              text-align: left;
              margin-left: 4px;
              font-size: 15px;
              font-weight: 500;
            }

          }
          
        }

        .headPicker{
          padding-top: 20px;

          .img-container{
            margin-top: -26px;
          }
        }

        .colorPicker{
          width: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 10px 10px;
          justify-content: center;
          align-items: center;
          margin-left: 25px;

          input[type="radio"] {
            display: none;
            &:checked + label {
              span { 
                transform: scale(1.25); 
                border-radius: 50%;
              }
             // !@each
            } // !&:checked + label
          }
  
          label {
            display: block;
            margin-top: 22px;
            width: 35px;
            height: 35px;
            margin-right: 75px;
            text-align: center;
            cursor: pointer;
            &:hover {
              span { 
                transform: scale(1.25); 
              }
            }
            span {
              display: block;
              width: 100%;
              height: 100%;
              border-radius: 50%;
              margin-bottom: 3px;
              transition: transform .2s ease-in-out;
            }
  
            .other {
              background-image: url("./../../images/icon/colorflow.png");
              display: block;
              width: 100%;
              height: 100%;
              border-radius: 50%;
              margin-bottom: 3px;
              transition: transform .2s ease-in-out;
              border: 2px solid darken(black, 25%);
            }
  
            .other:hover , .other:active , .other:checked , .other:focus-visible{
              border: 2px solid darken(black, 25%);
            }
          }

        }

      
    
    }

  }

  .filters__container.activation{
    opacity: 1;
    pointer-events: all;
    z-index: 900;
    top:0%;
    transform: translateZ(0);
    transition: 0.5s all 0.5s ease-out;
    }

    .filters__container.activation.widthView{
      transform: translateX(65vw) translateX(-400px);

      @include mq(1350){
        transform: translateX(300px) 
      }
    }

    .snake__preview{

      @include mq(1350){
        top:130px;
      }

      position: absolute;
      top:60px;
      left: 460px;
      opacity: 0;
      pointer-events: none;
      transition: 0.2s all 0.8s ease-in-out;
      z-index: 99999999999;

      img{
      width: 800px;
      height: 600px;
      @include mq(1350){
        width: 650px;
        height: 450px;
      }
      }

      .preview_header{
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 280px;
        top: 45px;
        left: -20px;
        padding: 20px;
        border-radius: 4px;
        background-color: white;
        border-bottom: #17513D 1px solid;

        h2{
          font-size: 22px ;
        }
      }
    }

    .snake__preview.activated{
      opacity: 1;
      left: 500px;
    }

  .content {
    margin-left: 24px;
  }
  .risk__level {
    margin-right: 24px;

    font-weight: 500;
    font-size: 20px;

    color: #fff;
    padding: 16px 10px;

    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      margin-right: 16px;
    }
  }
  .high_risk {
    border-radius: 6px;
    background: #f99370;
  }
  .medium__risk {
    background: #f7f78e;
    svg {
      transform: rotate(90deg);
    }
  }

  .medium__risk1
  {
    background: #f7c175;
    svg {
      transform: rotate(45deg);
    }
  }
  .low__risk1{
    background: #79eb4b;
    svg {
      transform: rotate(180deg);
    }
  }

  .low__risk {
    background: #8ae7d2;

    svg {
      transform: rotate(180deg);
    }

  
  }
  p {
    font-weight: normal;
    font-size: 16px;
    color: #9b9999;
    max-width: 285px;
    margin-top: 24px;
    margin-bottom: 15px;
  }
  .lnglat {
    margin-bottom: 20px;
    li {
      font-size: 17px;
      color: #4d4d4d;
      margin-bottom: 5px;
      span {
        margin-right: 10px;
        font-size: 16px;
        color: #848484;
      }
    }
  }

  

  .snake__list {

    .snake_list_header{
     display: flex;
     flex-direction: row;
     flex-wrap: nowrap;
     align-items: center;
     margin-bottom: 23px;
      h6 {
        font-weight: bold;
        font-size: 26px;
        color: #00152f;
      }

      .gosht{
        margin: 30px;
        width: 150px;
        position: relative;
         overflow: hidden;
        &:hover .shine {
          transform: skewX(20deg) translateX(300px);
        }
      }

      .shine {
        position: absolute;
        top: 0;
        left: -70px;
        height: 98px;
        width: 50px;
        background: rgba(255, 255, 255, 0.4);
        transition: all .4s linear;
        transform: skewX(20deg) translateX(0);
        cursor: pointer;
      }

      button{
        border: 0;
        width: 200px;
        background: steelblue;
        color: white;
        font-weight: bold;
        transition: all .3s linear; 
        border-radius: 6px;
        border: 2px solid $dark-blue-220;
        color: $white;
        font-size: 18;
        font-weight: 500;
        text-align: center;
        width: 85px;
        margin-left: 35px;

        &:hover {
          color: white;
          box-shadow: 0 0 30px 0 transparentize($green400, 0.5);
          background-color: $green300;
          transition: all 0.2s ease-out;
    
        }
      
      }

    }
  
    ul {

      .filtered{
        //animation: filtering 0.5s ease-in-out;
        height: 0px;
        margin: 0;
        padding: 0;
        opacity: 0;
        cursor: default;
        pointer-events: none;
      }

      li {
        margin-bottom: 26px;
        width: 290px;
        height: 54.49px;
        border-radius: 27.25px;
        transition: all 0.4s ease-in-out;

  
        .bubbleInfo{
        position: absolute;
        display: none;
        background: #000000;
        color: #FFFFFF;
        font-family: Arial;
        font-size: 20px;
        line-height: 120px;
        box-shadow: 1px 4px 2px 0px #A3A3A3;
        text-align: center;
        width: 820px;
        height: 590px;
        border-radius: 10px;
        padding: 0px;
        left: 500px;
        top: 80px;
        transition: all 1s ease-in-out;
        opacity: 0;

        img{
          width: 100%;
          height: 100%;
        }
        }
       

       &:hover .bubbleInfo{
      
        visibility: visible;
        opacity: 1;
        display: none;

       }

        a , .Snakelink {
          border-radius: 27.25px;
          width: 100%;
          height: 100%;
          background: #fff;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 17px;
          line-height: 24px;
          color: #00152f;
          transition: all 0.6s ease-out;

          span {
            position: absolute;
            top: -1px;
            left: -1px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 54.49px;
            height: 54.49px;

            border-radius: 100%;
          }

          
        }
        .snake_v_button{
          background: #ff7a86;
        }

        .snake_s_button{
          background-color: #ffa558;
        }

        .snake_n_button{
          background-color: #09887d;
        }
      }
      .snake_v {
        border: 2px solid #ff7a86;
        span {
          background: #ff7a86;
        }
      }
      .snake_s {
        border: 2px solid #ffa558;
        span {
          background: #ffa558;
        }
      }
      .snake_n {
        border: 2px solid #09887d;
        span {
          background: #09887d;
        }
      }
    }
  }
}

.snake__modal {
  .content {
    margin: 24px;
  }
  img {
    width: 100%;
    margin-bottom: 30px;
  }
  p {
    height: 300px;
    font-family: Prompt;
    font-weight: normal;
    font-size: 16px;
    line-height: 38px;
    color: #7f7f7f;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #eaeaea;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #aeb1cd;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  a {
    display: block;
    border-radius: 10px;
    background: #fff;
    border: 2px solid #5f65a6;
    padding: 16px 10px;
    text-align: center;
    margin-top: 30px;

    font-size: 20px;

    color: #5f65a6;
  }
}
.snake__info__modal {
  h3 {
    font-size: 22px;

    color: #00152f;
  }
  div {
    cursor: pointer;
    margin-top: 11px;
    font-size: 15px;
    text-align: left;
    color: #5f65a6;
    svg {
      margin-left: 10px;
    }
  }
}

.filter__mobile__close {
  width: 100%;
  display: block;
  background: #5f65a6;
  padding: 16px 10px;
  border-radius: 10px;
  color: #fff;
  display: none;
  @include mq(992){
    display: block;
  }
}

.feedback__step {
  .get__feedback {
    text-align: center;
    .snake__info {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 56px;
      .image {
        width: 566px;
        @include mq(768) {
          width: 80%;
          margin: 0 auto;
        }
        height: 316px;
        border-radius: 7px;
        overflow: hidden;
        margin-bottom: 22px;
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }

      p {
        font-size: 22px;
        font-weight: 400;
        color: $dark-blue-400;
      }
    }
    .feedback__rating {
      p {
        font-size: 30px;
        font-weight: 400;
        color: $dark-blue-400;
        margin-bottom: 35px;
      }
      .feedback__item__box {
        .feedback {
          --normal: #eceaf3;
          --normal-shadow: #d9d8e3;
          --normal-mouth: #9795a4;
          --normal-eye: #595861;
          --active: #f8da69;
          --active-shadow: #f4b555;
          --active-mouth: #f05136;
          --active-eye: #313036;
          --active-tear: #76b5e7;
          --active-shadow-angry: #e94f1d;
          margin: 0;
          padding: 0;
          list-style: none;
          display: flex;
          justify-content: center;
          li {
            position: relative;
            border-radius: 50%;
            background: var(--sb, var(--normal));
            box-shadow: inset 3px -3px 4px var(--sh, var(--normal-shadow));
            transition: background 0.4s, box-shadow 0.4s, transform 0.3s;
            -webkit-tap-highlight-color: transparent;
            &:not(:last-child) {
              margin-right: 20px;
            }
            div {
              width: 40px;
              height: 40px;
              position: relative;
              transform: perspective(240px) translateZ(4px);
              svg,
              &:before,
              &:after {
                display: block;
                position: absolute;
                left: var(--l, 9px);
                top: var(--t, 13px);
                width: var(--w, 8px);
                height: var(--h, 2px);
                transform: rotate(var(--r, 0deg)) scale(var(--sc, 1))
                  translateZ(0);
              }
              svg {
                fill: none;
                stroke: var(--s);
                stroke-width: 2px;
                stroke-linecap: round;
                stroke-linejoin: round;
                transition: stroke 0.4s;
                &.eye {
                  --s: var(--e, var(--normal-eye));
                  --t: 17px;
                  --w: 7px;
                  --h: 4px;
                  &.right {
                    --l: 23px;
                  }
                }
                &.mouth {
                  --s: var(--m, var(--normal-mouth));
                  --l: 11px;
                  --t: 23px;
                  --w: 18px;
                  --h: 7px;
                }
              }
              &:before,
              &:after {
                content: "";
                z-index: var(--zi, 1);
                border-radius: var(--br, 1px);
                background: var(--b, var(--e, var(--normal-eye)));
                transition: background 0.4s;
              }
            }
            &.angry {
              --step-1-rx: -24deg;
              --step-1-ry: 20deg;
              --step-2-rx: -24deg;
              --step-2-ry: -20deg;
              div {
                &:before {
                  --r: 20deg;
                }
                &:after {
                  --l: 23px;
                  --r: -20deg;
                }
                svg {
                  &.eye {
                    stroke-dasharray: 4.55;
                    stroke-dashoffset: 8.15;
                  }
                }
              }
              &.active {
                animation: angry 1s linear;
                div {
                  &:before {
                    --middle-y: -2px;
                    --middle-r: 22deg;
                    animation: toggle 0.8s linear forwards;
                  }
                  &:after {
                    --middle-y: 1px;
                    --middle-r: -18deg;
                    animation: toggle 0.8s linear forwards;
                  }
                }
              }
            }
            &.sad {
              --step-1-rx: 20deg;
              --step-1-ry: -12deg;
              --step-2-rx: -18deg;
              --step-2-ry: 14deg;
              div {
                &:before,
                &:after {
                  --b: var(--active-tear);
                  --sc: 0;
                  --w: 5px;
                  --h: 5px;
                  --t: 15px;
                  --br: 50%;
                }
                &:after {
                  --l: 25px;
                }
                svg {
                  &.eye {
                    --t: 16px;
                  }
                  &.mouth {
                    --t: 24px;
                    stroke-dasharray: 9.5;
                    stroke-dashoffset: 33.25;
                  }
                }
              }
              &.active {
                div {
                  &:before,
                  &:after {
                    animation: tear 0.6s linear forwards;
                  }
                }
              }
            }
            &.ok {
              --step-1-rx: 4deg;
              --step-1-ry: -22deg;
              --step-1-rz: 6deg;
              --step-2-rx: 4deg;
              --step-2-ry: 22deg;
              --step-2-rz: -6deg;
              div {
                &:before {
                  --l: 12px;
                  --t: 17px;
                  --h: 4px;
                  --w: 4px;
                  --br: 50%;
                  box-shadow: 12px 0 0 var(--e, var(--normal-eye));
                }
                &:after {
                  --l: 13px;
                  --t: 26px;
                  --w: 14px;
                  --h: 2px;
                  --br: 1px;
                  --b: var(--m, var(--normal-mouth));
                }
              }
              &.active {
                div {
                  &:before {
                    --middle-s-y: 0.35;
                    animation: toggle 0.2s linear forwards;
                  }
                  &:after {
                    --middle-s-x: 0.5;
                    animation: toggle 0.7s linear forwards;
                  }
                }
              }
            }
            &.good {
              --step-1-rx: -14deg;
              --step-1-rz: 10deg;
              --step-2-rx: 10deg;
              --step-2-rz: -8deg;
              div {
                &:before {
                  --b: var(--m, var(--normal-mouth));
                  --w: 5px;
                  --h: 5px;
                  --br: 50%;
                  --t: 22px;
                  --zi: 0;
                  opacity: 0.5;
                  box-shadow: 16px 0 0 var(--b);
                  filter: blur(2px);
                }
                &:after {
                  --sc: 0;
                }
                svg {
                  &.eye {
                    --t: 15px;
                    --sc: -1;
                    stroke-dasharray: 4.55;
                    stroke-dashoffset: 8.15;
                  }
                  &.mouth {
                    --t: 22px;
                    --sc: -1;
                    stroke-dasharray: 13.3;
                    stroke-dashoffset: 23.75;
                  }
                }
              }
              &.active {
                div {
                  svg {
                    &.mouth {
                      --middle-y: 1px;
                      --middle-s: -1;
                      animation: toggle 0.8s linear forwards;
                    }
                  }
                }
              }
            }
            &.happy {
              div {
                --step-1-rx: 18deg;
                --step-1-ry: 24deg;
                --step-2-rx: 18deg;
                --step-2-ry: -24deg;
                &:before {
                  --sc: 0;
                }
                &:after {
                  --b: var(--m, var(--normal-mouth));
                  --l: 11px;
                  --t: 23px;
                  --w: 18px;
                  --h: 8px;
                  --br: 0 0 8px 8px;
                }
                svg {
                  &.eye {
                    --t: 14px;
                    --sc: -1;
                  }
                }
              }
              &.active {
                div {
                  &:after {
                    --middle-s-x: 0.95;
                    --middle-s-y: 0.75;
                    animation: toggle 0.8s linear forwards;
                  }
                }
              }
            }
            &:not(.active) {
              cursor: pointer;
              &:active {
                transform: scale(0.925);
              }
            }
            &.active {
              --sb: var(--active);
              --sh: var(--active-shadow);
              --m: var(--active-mouth);
              --e: var(--active-eye);
              div {
                animation: shake 0.8s linear forwards;
              }
            }
          }
        }

        @keyframes shake {
          30% {
            transform: perspective(240px) rotateX(var(--step-1-rx, 0deg))
              rotateY(var(--step-1-ry, 0deg))
              rotateZ(var(--step-1-rz, 0deg)) translateZ(10px);
          }
          60% {
            transform: perspective(240px) rotateX(var(--step-2-rx, 0deg))
              rotateY(var(--step-2-ry, 0deg))
              rotateZ(var(--step-2-rz, 0deg)) translateZ(10px);
          }
          100% {
            transform: perspective(240px) translateZ(4px);
          }
        }

        @keyframes tear {
          0% {
            opacity: 0;
            transform: translateY(-2px) scale(0) translateZ(0);
          }
          50% {
            transform: translateY(12px) scale(0.6, 1.2) translateZ(0);
          }
          20%,
          80% {
            opacity: 1;
          }
          100% {
            opacity: 0;
            transform: translateY(24px) translateX(4px) rotateZ(-30deg)
              scale(0.7, 1.1) translateZ(0);
          }
        }

        @keyframes toggle {
          50% {
            transform: translateY(var(--middle-y, 0))
              scale(
                var(--middle-s-x, var(--middle-s, 1)),
                var(--middle-s-y, var(--middle-s, 1))
              )
              rotate(var(--middle-r, 0deg));
          }
        }

        @keyframes angry {
          40% {
            background: var(--active);
          }
          45% {
            box-shadow: inset 3px -3px 4px var(--active-shadow),
              inset 0 8px 10px var(--active-shadow-angry);
          }
        }
      }
    }
    .form__wrapper {
      form {
        padding: 50px;
       
        > .items {
          .item {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            width: 100%;
            margin-bottom: 30px;
            label {
              color: $dark-blue-220;
              font-size: 22px;
              font-weight: 400;
              margin-bottom: 17px;
              position: relative;
              &.is__required {
                &::after {
                  content: url("./../../images/icon/required.svg");
                  position: absolute;
                  top: 0;
                  right: -13px;
                }
              }
            
            }

            @include mq(768) {
              text-align: left;
              font-size: 20px;
            }


            select
            {
              width: 50%;
            }

            input[type="text"] {
              width: 100%;
              background-color: $white;
              border-radius: 5px;
              border: 1px solid rgba($gray350, 0.37);
              padding: 15px 0 14px 25px;
            }
            textarea {
              width: 100%;
              background-color: $white;
              border-radius: 5px;
              border: 1px solid rgba($gray350, 0.37);
              padding: 15px 0 0 25px;
              resize: none;
              max-height: 197px;
              height: 197px;
            }
          }
        }
        .questions__box {
          > .items {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            select
            {
              width: 80%;
            }

            @include mq(768) {
              padding: 5px 5px;
              text-align: left;
              font-size: 20px;
            }

            > .item {
              margin-bottom: 20px;
              > label {
                margin-bottom: 10px;
                font-size: 20px;
                font-weight: 400;
                text-align: left;
              }
              
              select
              {
                width: 80%;
              }
              .checks__items {
                display: flex;
                align-items: flex-start;
                justify-content: flex-end;
                .item {
                  margin-left: 10px;
                  display: flex;
                  align-items: center;
                  label {
                    margin-left: 10px;
                    font-size: 15px;
                    text-align: right;
                  }
                  input[type="radio"] {
                    width: 16px;
                    height: 16px;
                  }
                }
              }
            }
          }
        }
        .button__wrapper {
          display: flex;
          justify-content: flex-start;
          margin-bottom: 30px;
          button[type="submit"] {
            border-radius: 6px;
            background-color: $green300;
            border: 2px solid $green400;
            color: $white;
            font-size: 18;
            font-weight: 500;
            padding: 20px 50px;
          }
        }
      }
    }
  }
  .thank__you__feedback {
    text-align: center;
    .actions {
      h4 {
        font-size: 27px;
        font-weight: 500;
        color: $dark-blue-400;
        margin-bottom: 20px;
      }
      .two {
        width: 480px;
        @include mq(768) {
          width: 100%;
          flex-direction: column;
        }
        margin: auto;
        display: flex;
        justify-content: space-between;
        button {
          &:first-child {
            width: calc(45% - 10px);
          }
          &:last-child {
            width: calc(55% - 10px);
          }
          @include mq(768) {
            width: 100% !important;
            margin-bottom: 20px;
          }
          border-radius: 6px;
          padding: 20px 0;
          color: $white;
          font-size: 18px;
          font-weight: 500;
          &.blue__button {
            background-color: $purple180;
            border: 2px solid $purple200;
          }
          &.green__button {
            background-color: $green300;
            border: 2px solid $green50;
          }
        }
      }
    }
  }
  .checked-items
  {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px $green300 solid ;
    border-bottom: 1px $green300 solid ;
    padding: 20px 0px;
    margin: 10px 0;

    @include mq(768)
    {
      flex-direction: column;
      padding: 20px 5px;
    }

    label
    {
      font-size: 20px;
      margin-bottom: 10px;
      font-weight: 400;
      width: 100%;
      
    }

    ul
    {
      width: 100%;
    }

     .checked-item
     {
       display: flex;
       flex-direction: column;
       justify-content: flex-end;
       align-items: center;
       width: 50%;
       text-align: left;

       @include mq(768)
       {
         width: 100%;
         
       }

        li{
          width: 100%;
          padding: 5px;

          label
          {
            margin-right: 10px;
            font-size: 15px;
            width: auto;
            text-align: right;
          }
        }

     }

  }
}

.result__step {
  display: flex;
  margin-top:100px;
  margin-bottom: 100px;
  padding: 50px;
  justify-content: space-between;
  @include mq(992) {
    flex-direction: column-reverse;
  }
  .left {
    width: calc(50% - 30px);
    @include mq(992) {
      width: 100%;
    }
    .snake__info {
      display: flex;
      flex-direction: column;
      .title {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        svg {
          margin-right: 12px;
        }
        h3 {
          font-size: 33px;
          font-weight: 500;
          color: $dark-blue-400;
        }
      }
      .fine__information {
        display: flex;
        align-items: center;
        margin-bottom: 18px;
        .notice {
          margin-right: 23px;
          span {
            font-size: 17px;
            font-weight: 400;
            color: $dark-blue-400;
            position: relative;
            display: flex;
            align-items: center;
            margin-left: 16px;
            &::before {
              content: "";
              position: absolute;
              width: 8px;
              height: 8px;
              left: -16px;
              border-radius: 50%;
              background-color: $dark-blue-400;
            }
          }
        }
        .location {
          svg {
            margin-right: 7px;
          }
          span {
            font-size: 17px;
            font-weight: 400;
            color: $dark-blue-400;
          }
        }
      }
      .description {
        p {
          font-size: 15px;
          font-weight: 400;
          line-height: 40px;
          color: $gray400;
        }
      }
    }
  }
  .right {
    width: calc(50% - 30px);
    @include mq(992) {
      width: 100%;
      margin-bottom: 30px;
    }
    .gallery {
      margin-bottom: 27px;
      .master__image {
        width: 100%;
        height: 362px;
        border-radius: 7px;
        overflow: hidden;
        margin-bottom: 12px;
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
      .gallery__component {
        .gallery__items {
          display: flex;
          flex-wrap: wrap;
          .gallery__item {
            width: 92px;
            height: 73px;
            margin-right: 17px;
            margin-bottom: 10px;
            border-radius: 7px;
            overflow: hidden;
          }
          .more__items {
            width: 92px;
            height: 73px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $gray200;
            border-radius: 7px;
            overflow: hidden;
            margin-right: 17px;
            margin-bottom: 10px;
          }
        }
      }
    }
    .location {
      height: 274px;
      max-height: 274px;
    }
  }
  .tell__us__feedback {
    position: fixed;
    padding: 24px 0;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 10;
    background-color: $green800;
    display: flex;
    justify-content: center;
    align-items: center;
    @include mq(450) {
      flex-direction: column;
    }
    h4 {
      margin-right: 20px;
      @include mq(450) {
        margin-right: 20;
        margin-bottom: 20px;
      }
      color: $white;
      font-size: 22px;
      font-weight: 500;
    }
    button {
      background-color: $white;
      border-radius: 9px;
      font-size: 18px;
      font-weight: 500;
      color: $dark-blue-400;
      padding: 10px 20px;
    }
  }

}
.boxLegend {
  background-attachment: scroll;
  background-clip: border-box;
  background-image: none;
  background-origin: padding-box;
  background-size: auto;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: inline-block;
  font-family: 'Open Sans', sans-serif;
  font-size: 32px;
  font-stretch: normal;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  height: 1px;
  line-height: normal;
  margin-bottom: 5px;
  margin-left: 9.28px;
  margin-right: 9.28px;
  margin-top: 8px;
  min-height: 0px;
  min-width: 0px;
  outline-width: 0px;
  padding-bottom: 2px;
  padding-left: 24px;
  padding-right: 30px;
  padding-top: 16px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition-delay: 0s;
  transition-duration: 0.28s;
  transition-property: box-shadow;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  width: 1px;
  z-index: 0;
  -webkit-user-select: none;
  }

.veryHigh {
  background-color: #f99370;
}

.high {
  background-color: #f7c175;
}

.medium {
  background-color: #f7f78e;
}

.low {
  background-color: #afff14;
}

.veryLow {
  background-color: #8ae7d2;
}

.legend{

  display: flex;
  justify-content: flex-start;
  align-items: center

  

}

.legendIcon{
   margin-left: 8.5px; 
   margin-right: 8.5px; 
   margin-top: 10px;
   border-top: #009f9d;
   border: #009f9d dashed 0.5px;
   border-bottom:rgb(255, 255, 255);
   border-right: rgb(255, 255, 255);
   border-left: rgb(255, 255, 255);
   padding-top: 10px;
   padding-bottom: 10px;



}
.legendIcon2{
  margin-left: 14px; 
  margin-right: 14px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: #009f9d;
  border: #009f9d solid 0.5px;
  border-top:rgb(255, 255, 255);
  border-right: rgb(255, 255, 255);
  border-left: rgb(255, 255, 255);
}

.swiper{
  width :325px;
  height: 240px;
  padding: 0;
  margin: 0;
  border:rgb(29, 2, 2) 2px solid;
}

.swiper-wrapper{
  padding-inline-start: 0;
}

.swiper-slide-active{
  display: flex-start;
  justify-content: center;
  align-items: center;
  h3{
    text-align: center;
  }

  .patternPicker , .headPicker{
    height: 100%;
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;
    margin-left: 0px;
    margin-top: 5px;

    .img-container{

      float: right;
      position: absolute;
      left:200px; 
      width: 110px;
      height: 110px;
      border: black 1px solid;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      
      img{
        
      }
    }

   
    .radioGroup{
      display: flex;
      align-items: center;
      width: 180px;
      padding-left: 10px;

      input[type=radio]{
        padding: 0.5em;
        -webkit-appearance: none;
        outline: 0.1em solid #02363e ;
        outline-offset: 0.1em;
        width: 14px;
        height: 14px;
      }

      input[type=radio]:checked {
        display: inline-block;
        background-color: #02363e;
      }

      label{
        text-align: left;
        margin-left: 4px;
        font-size: 15px;
        font-weight: 500;
      }

    }
    
  }

  .headPicker{
    padding-top: 20px;

    .img-container{
      margin-top: -6px;
    }
  }

  .eyePicker
        {
          display: flex;
          justify-content: center;
          align-items: center;
          column-gap: 30px;
          padding-left:0px;
          width: 100%;
          height: 70%;

          input[type=radio]{
            width: 0;
            height: 0;
            position: fixed;
          }

          label {
            cursor: pointer;
            display: inline-block;
            background-color: #ddd;
            padding: 4px 20px;
            text-align: center;
            font-size: 16px;
            border: 2px solid #444;
            border-radius: 4px;
            margin-top: -30px;
        }

        input[type="radio"]:checked + label {
          background-color:#bfb;
          border-color: #4c4;
        }
        input[type="radio"]:focus + label {
          border: 2px dashed #444;
        }

        label:hover {
          background-color: #dfd;
        }

          .roundEye , .verticalEye{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 74px;
            height: 74px;
            background-color: white;
            border-radius: 50%;
            border: 3px solid black ;

            .pupil{  
            width: 52px;
            height: 52px;
            background-color: #111111;
            border-radius: 50%;
            }

            .pupil::before {
              content: ' ';
              width: 10px;
              height: 12px;
              transform: translateX(5px);
              background-color: white;
              display: block;
              border-radius: 50%;
            }

          }



          .verticalEye{
            background-color: white;
            border: 3px solid black;
             .pupil{
              display: flex;
              justify-content: center;
              align-items: center;
              width: 90%;
              height: 90%;
              background-color: white;
              border-radius: 50%;
              border: black 2px solid
              }
  
               .pupil::before {
                content: ' ';
                width: 100%;
                height: 100%;
                background-color: black;
                display: block;
                transform: translateX(0px);
                -webkit-clip-path: ellipse(9% 50% at 50% 50%);
                clip-path: ellipse(5% 50% at 50% 50%);
              }
          }
        }
}

.swiper-button-next,
.swiper-button-prev {
    background-color: white;
    background-color: rgba(16, 177, 29, 0.1);
    border-radius: 4px;
    width: 32px;
    height: 32px;
    right:10px;
    top:80%;
    padding: 30px;
    color: #000 !important;
    fill: black !important;
    stroke: black !important;
}

.mobileFilter{
  display: none;
  @include mq(992){
    display: block;
    height: 0px;
    width: 0;
    opacity: 0;
    pointer-events: none;
    transition: all 0.6s ease-in-out;
  }
  
}

.mobileFilter.activation{
  @include mq(992){
    display: block;
    width :325px;
    height: 240px;
    opacity: 1;
    pointer-events:all;
    transition: all 0.6s ease-in-out;

    .filter__header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      border-bottom: 0.8px solid rgba($dark-blue-400,0.6);

      h3{
        font-size: 22px;
        font-weight: 800;
        width: 80%;
        margin-left: -5px;
    }

    button{
      position: relative;
      right: 5px;
      top: 0px;
      font-size: 16px;
      margin: 0;
     text-decoration: underline;
     font-weight: 600;
    }

    }
  }

}

.img-container{

  float: right;
  position: absolute;
  left:255px; 
  width: 130px;
  height: 130px;
  border: black 1px solid;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -6px;
  
  img{
    
  }
}

.resetFilters{
  background-color: white;
}