html.view-height {
  body,
  #root {
    width: 100vw;
    height: 100vh;
  }
  #root {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
