.custom__marker {
  .flag {
    position: absolute;
    top: -88px;
    left: -32px;
    img {
      width: 47px;
      height: 47px;
      border-radius: 100%;
      position: absolute;
      top: 6px;
      left: 13%;
      background:red;
    }
  }
}
