

section.treatment{
    padding-top :40px;

    section.question__box{

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        height: 600px;

        @include mq(750){
          margin-top: -45px;
        }
        
      
        span{
          color: #851919;
          font-weight: bold;
        }
      
       > .question__quote{
         background:white;
         width: 80vw;
         height: 100px;
         display: flex;
         justify-content: center;
         align-items: center;
         @include mq(572){
            padding: 5px;
            margin-top: -30px;
         }
          h2{
            font-size: 54px;
            color: #001728;
            font-weight: 500;
            text-align: center;
            @include mq(1300){
              font-size: 32px;
              text-align: center;
            }
            @include mq(576){
              font-size: 25px;
              text-align: center;
            }
          }

          .question__disc{

            list-style:disc;
            font-size: 17px;
            font-weight: 200;
           
            @include mq(750){
              margin-top: 370px;
              line-height: 1.3;
            }
          }

          h6{
            margin-top: 110px;
          }
       }
      
       > .question__response{
         width: 50vw;
         height: 250px;
         padding-top: 10px;
         display: flex;
         justify-content: space-evenly;
         align-items: center;

         @include mq(560){
           justify-content: center;
           flex-direction: column;
           margin-top: -30px;
         }
         
         button:first-child{
             width: 200px;
             padding: 20px;
             font-size: 25px;
             font-weight: bold;
             border:solid $green100 1.5px;
             color: $green100;
             border-radius: 5px;
             margin-bottom: 15px;                 
             transition: all 1s ease-out;
         }
         
         button:first-child:hover{
            background: $green100;
            color: white;   
        }

         button:nth-child(2){
            width: 200px;
            padding: 20px;
            font-size: 25px;
            font-weight: bold;
            border:solid $red100 1.5px;
            color: $red100;
            border-radius: 5px;                  
            transition: all 1s ease-out;
        }

        button:nth-child(2):hover{
            background: $red100;
            color: white;   
        }

        
        }
    }

  /*This part is for the adaptable box for the tretment part*/  
  .contentBox{
    width: 100%;
    height: 590px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .symptomBox{
        width: 80%;
        height: 80%;
        border: solid #02363e  2px;

        @include mq(750){
          height: 88%;
        }

        h2{
            text-align: center;
            width: 100%;
            padding: 5px;
            background: linear-gradient(#02363e 0%, #035855 100%);
            color: white;
        }

        .basic__form__container, .form__container{
            display: flex;
            align-items: center;
            justify-content: space-evenly  ;
            flex-wrap: wrap;
            width: 100%;
            height: 100%;

            @include mq(750){
              height: auto;
            }

            > .element{
              /* width: 50%;
               display: flex;
               align-items: center;
               justify-content: center;*/
               input[type=radio] {
                padding: 0.5em;
                -webkit-appearance: none;
                outline: 0.1em solid #02363e ;
                outline-offset: 0.1em;
                width: 16px;
                height: 16px;
                margin-top: 30px;

              }
              
              input[type=radio]:checked {
                display: inline-block;
                background-color: #02363e;
              }
            }

              label
              {
                font-size: 18px;
                margin-left: 15px;
                font-weight: 400;
              }
        }
        
        .form__container{
            align-items: flex-start;
            padding-top: 45px;

            .confirmedSymptom{

                display: flex;
                justify-content: flex-start;
                align-items: flex-start; 
                flex-direction: column;
                width: 100%;
                height: 200px;
                padding-left: 6.5%;
                opacity:0;
                transition: all 0.5s linear;
                padding-top: 10px;

                label{
                  @include mq(750){
                    font-size: 14px;
                  }
                }
            }


            .confirmedSymptom.active{
                display: flex;
                justify-content: flex-start;
                align-items: flex-start; 
                flex-direction: column;
                width: 100%;
                height: 200px;
                padding-left: 10.5%;
                opacity:1;
                padding-top: 10px;

                @include mq(750){
                  padding: 0;
                }

                h5{
                  font-size: 19px;
                  @include mq(750){
                    font-size: 15px;
                    padding: 5px;
                  }
                }
                

                >.element{
                    margin-left: 25px;
                    margin-top: 20px;

                    @include mq(750){
                      margin-left: 10px;
                      display: flex;
                      flex-wrap: nowrap;
                      flex-direction: row;
                      justify-content: center;
                      align-items: center;
                     
                    }
                    
                    input[type=radio] {
                        width: 16px;
                        height: 16px;

                        @include mq(750){
                          width: 25px;
                          height: 25px;
                          min-width:20px;
                        }
                      }
                      
                      input[type=radio]:checked {
                        background-color: #02363e;
                      }

                      label{
                        @include mq(750){
                          font-size: 14px;
                        }
                      }
                }

            }

        }

    }
    .navigation
    {
        position: absolute;
        width: 100%;
        padding: 40px 20px;
        display: flex;
        justify-content: space-between;
        top:75%;
        left: 0%;

        > img{
            background: #0000;
            width: 80px;
            height: 80px;
            border-radius: 6px;
            cursor: pointer;
        }
    }

    .previous , .next{
      background: #0000;
      width: 90px;
      height: 90px;
      border-radius: 6px;
      cursor: pointer;
    }

    .previous{
      position: absolute;
      top:55%;
      left:3%;


      @include mq(750){
        top:80%;
      }
    }

    .next{
      position: absolute;
      top:55%;
      right: 3%;
      
      @include mq(750){
        top:80%;
      }
    }

    .guideimg{

      display: flex;
      align-items: center;
      justify-content: center;
      position:absolute;
      float:right;
      width: 180px;
      pointer-events: none;
      right: 130px;
      bottom: 150px;

      @include mq(750){
        position:absolute;
        float:right;
        width: 500px;
        pointer-events: none;
        right: -60px;
        bottom: 10px;
      }

    }

    .infoimg{

      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      position:relative;
      float:right;
      margin-left: 5px;


    }

  }

  .wbctHelp{

    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 70px -30px 0px;
    h4{

      margin-right: 10px;
      margin-left: 100px;
      color: #008281;
   

    }
    
    @include mq(750){
      position: relative;
      justify-content: center;
      top: 70%;
      padding: 2px 20px;
    }
    

  }
  
  .resultBox{
    width: 100%;
    height: auto;
    display: flex;
    gap: 50px;
    padding: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    div > .auth_required{
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      row-gap: 15px;
      padding-top: 20px;

      button{
        border-radius: 6px;
        background: linear-gradient(#035855 0%, #02363e 100%);
        border: 1px solid $green400;
        color: $white;
        font-size: 18;
        font-weight: 500;
        padding: 5px 15px;
      }
    }

    h4{
      text-align: center;
    }

    h2{
      color: #02363e;
      font-size: 45px;
      text-align: center;

      @include mq(750){
        font-size: 32px;
      }

      span:nth-child(1){
        color:#851919;
      }
      span:nth-child(2){
        color: #02363e;
      }
    }

    > div{
      width: 80%;
      height: auto;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      border-radius: 10px;
      box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);

      @include mq(852){
        flex-direction: column;
        width: 100%;
      }

      .sub-section{
        width: 18%;
        min-width: 220px;
        border-radius: 10px 0 0 10px;
        background-color: #2A265F;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include mq(852){
          width: 100%;
          border-radius: 10px 10px 0 0;
          flex-direction: row;
        }

        h3{
          text-align: left;
          color: #fff;
          width: 60%;
          padding-bottom: 30px;
          padding-left: -10px;
          font-size: 25px;
        }
      }

      .content{
        width: 80%;
        padding: 25px 50px;
        font-size: 17px;
        @include mq(850){
          width: 100%;
          padding: 5px 25px;
          font-size: 15px;
        }
        ul{
          list-style: square;
          ul{
            padding-left: 30px;
            list-style: circle;
          }
        }
      }
    }

    .specific__treatment{
      .sub-section{
        background-color: #851819;
        img{
          background: white;
          align-self: flex-start;
          justify-self: flex-start;
          border-radius: 50%;
          position: relative;
          top: -48px;
          left: -20px;
          /* width: 120px; */
          transform: scale(1.1);
          border: #02363e solid 2px;
          width: 60px;
        }
      }
    }

    .clinical__treatment{
      .sub-section{
        background-color: #008281;
        padding-bottom: 30px;
        img{
          background: white;
          align-self: flex-start;
          justify-self: flex-start;
          border-radius: 50%;
          position: relative;
          top: 7px;
          left: -20px;
          /* width: 120px; */
          border: #2A265F solid 2px;
          width: 64px;
        }
      }
    }

    .symptomatic__treatment{
      .sub-section{
        background-color: #5b8751;
        
        
          img{
            background: white;
            align-self: flex-start;
            justify-self: flex-start;
            border-radius: 50%;
            position: relative;
            top: -45px;
            left: -20px;
            /* width: 120px; */
            transform: scale(1);
            border: #2A265F solid 2px;
            width: 68px;
          }
      }
    }
  }

  .reference{
    background: linear-gradient(#02363e 0%, #035855 100%);
    padding: 24px;
    align-items: center;
    h6{
      color: #fff;
      padding: 4px;
      font-size: 14px;
    }

    span{
      font-size: 18px;
      color: #f0f0f0;
    }
  }
  
  .previous{
    position: absolute;
    top:91%;
    left:3%;
    width: 90px;
    height: 90px;
    cursor: pointer;
    
    @include mq(750){
      top:95%;
    }
  }

  .choiceguide{

    display:flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;

  }

}

.symptomGallery{

  max-width: 550px;
  top: 120px;
  

}

.home__modal{

  //text-align: center;

  .modal__content{

    display: flex;
    justify-content: center;
    text-align: center;

    .modal__header{
      background: linear-gradient(#02363e 0%, #035855 100%);
    }
    
    h6{
    font-weight: normal;
    font-size: 22px;
    color: #002c63;
    font-weight: normal;
    margin-top:15px;
    margin-bottom: 5px; 
    }

    .login__form__element{

      margin-bottom: 5px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      padding: 10px;
      
      input[type="text"],input[type="password"] {
        width: 320px;
        background-color: $white;
        border-radius: 5px;
        border: 1px solid rgba($gray350, 0.37);
        padding: 15px 0 14px 25px;

        @include mq(752){
          width: 90%;
        }
      }
  }

    label {
        color: $dark-blue-220;
        font-size: 22px;
        font-weight: 400;
        margin-bottom: 0px;
        color: #f0f0f0;
        position: relative;
        @include mq(752){
          font-size: 18px;
        }
        &.is__required {
          &::after {
            content: url("./../../images/icon/required.svg");
            position: absolute;
            top: 0;
            right: -13px;
          }
        }
    
    }

  }

} 

  .slider{
    margin:20px auto;
    width:60%;

  }
   
  .img-box img{
    width:100%;
  }

  .imgButton{

    margin-top: 40px;
    margin-right: 45px;
    margin-left: 65px;
    margin-bottom: 30px;
  }



.hoverText {
  position: relative;
  border-bottom: 1px dotted black;
  color: #851819;
  white-space: pre-line;
}

.hoverText:before {
  content: attr(data-hover);
  visibility: hidden;
  opacity: 0;
  width: 600px;
  background-color: #5b8751;
  color: #fff;
  text-align:left;
  border-radius: 10px;
  padding: 10px 0;
  transition: opacity 1s ease-in-out;
  font-size: 16px;

  position: absolute;
  z-index: 1;
  left: -300px;
  top: 110%;
  white-space: pre-line;
  padding-left: 10px;
  padding-right: 5px;

}

.hoverText:hover:before {
  opacity: 1;
  visibility: visible;
}

