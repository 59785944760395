div.Tsteps {
  margin-bottom: 20px;
  margin-top: 30px;
  .items {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 75px;
    @include mq(992) {
      overflow-x: scroll;
      max-width: 100%;
    }
    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;

      @include mq(992) {
        margin-bottom: 20px;
        margin-right: 20px;
      }

      @include mq(992) {
        margin-bottom: 20px;
        margin-right: 70px;
        &:nth-child(1) {
          .text {
            width: 150px;
          }
        }
        &:nth-child(2) {
          .text {
            width: 145px;
          }
        }
        &:nth-child(3) {
          .text {
            width: 70px;
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
      &.active {

        .number
        {
          background: linear-gradient(#02363e 0%, #035855 100%);
          h2{
            color: white;
          }
          
        }

        .icon 
        {
          background: linear-gradient(#02363e 0%, #035855 100%);
          filter: drop-shadow(0px 3px 17px rgba(2, 70, 73, 0.24));
          svg {
            path {
              fill: $white;
              stroke: $white;
            }
            g {
              fill: $white;
              stroke: $white;
            }
          }
        }
        &::after {
          border-color: $dark-blue-200;
        }
      }

      .number {
        margin-right: 13px;
        padding-left: 5px;
        border-radius: 50%;
        width: 57px;
        height: 57px;
        padding: 15px;
        border: 2px solid $dark-blue-200;
        display: flex;
        align-items: center;
        justify-content: center;

        h2{
        font-size: 27px;
        color: $dark-blue-400;
        padding-right: 2px;
        }
      }
      
      .text {
        span {
          font-size: 22px;
          font-weight: 400;
          color: $dark-blue-400;
        }
      }

      .text-treatment {
        padding-right: 10px;
        span {
          font-size: 18px;
          font-weight: 400;
          color: $dark-blue-400;
          text-align: left;
        }
      }
      &::after {
        content: "";
        width: 110px;
        height: 2px;
        top: 27px;
        border-top: 2px dashed rgba($dark-blue-200, 0.08);
        position: absolute;
        right: -110px;
        @include mq(1700) {
          width: 70px;
          right: -70px;
        }
        @include mq(1400) {
          width: 70px;
          right: -70px;
        }
        @include mq(1200) {
          width: 35px;
          right: -30px;
        }
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }

  
}
