.signIn__button{

    position: absolute;
    right: 100px;
    top: 20px;
    z-index: 105;
}

#login__modal , #signIn__modal , #SignUp__modal , .aside__login__modal{

    .modal__content{
        h2{
            font-size: 32px;
            color: white;
        }

        .sign_in{
          font-size: 16px;
          padding-top: 20px;
          span{
            color: red;
            text-decoration: underline;
            cursor: pointer;
          }
        }  
      }

    .close__icon{
        position: absolute;
        top: 2%;
        right: 10px;
        cursor: pointer;
    }


    .login__form__element{

        margin-bottom: 5px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        padding: 10px;

        
        input[type="text"],input[type="password"] {
          width: 100%;
          background-color: $white;
          border-radius: 5px;
          border: 1px solid rgba($gray350, 0.37);
          padding: 15px 0 14px 25px;

          @include mq(752){
            width: 90%;
          }
        }
    }

    label {
        color: $dark-blue-220;
        font-size: 22px;
        font-weight: 400;
        margin-bottom: 17px;
        color: #f0f0f0;
        position: relative;
        @include mq(752){
          font-size: 18px;
        }
        &.is__required {
          &::after {
            content: url("./../../images/icon/required.svg");
            position: absolute;
            top: 0;
            right: -13px;
          }
        }
      
      }

      .button__container{
        display: flex;
        justify-content: center;
        width: 100%;

        button[type="submit"] {
          border-radius: 6px;
          background-image: linear-gradient(250deg,#001728,#046f65);
          border: 2px solid #039757;
          color: #fff;
          font-size: 18px;
          font-weight: 500;
          width: 150px;
          padding: 10px;
        }
      }

}

.aside__login__modal{
    width: 20vw;
    height: 100vh;
    top: 0%;
    left: 120%;
    position: fixed;
    background:#297a58;
    z-index: 110;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 300px;
    transition: all 1s linear;
}



.aside__login__modal.active{
    width: 20vw;
    height: 100vh;
    top: 0%;
    left: 80%;
    position: fixed;
    background:#297a58;
    z-index: 110;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 300px;

}