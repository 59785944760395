.guideline
{
  width: 100%;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  border-bottom: #046f65 solid 1px;
  padding: 20px 0;

  p{
    font-size: 18px;
    margin-left: 25px;
  }

  @include mq(750){
    flex-direction: column;
    img{
      width: 100vw;
      background-position: center;
      background-size: cover;
    }
  }
  
}

section.home {
  @include mq(992) {
    margin-top: 20px;
  }
  h1 {
    font-weight: bold;
    font-size: 46px;
    text-align: center;
    color: #00152f;
    margin-top: 78px;
    @include mq(992) {
      margin-top: 0;
    }
    margin-bottom: 70px;
    @include mq(992) {
      font-size: 30px;
    }
  }

  .button-container{

    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;

    @include mq(992){
      margin-top: 0;
    }
    
    .starter{
      background: rgb(41,122,88);
      background: linear-gradient(90deg, rgba(41,122,88,1) 0%, rgba(75,134,64,1) 100%);
      border: 2px solid $green100;
      padding: 20px 85px;
      border-radius: 6px;
      font-size: 18px;
      font-weight: 500;
      color: whitesmoke;
      text-transform: uppercase;
      transition: all 0.4s ease-out;
      
    }

    .starter:hover{
      color: $orange200;
      border: 2px solid $orange200;
    }
  }

  
 
  .items {
    display: flex;
    justify-content: center;
    @include mq(992) {
      flex-wrap: wrap;
    }
    .item {
      width: calc(33.33% - 24px);
      margin: 12px;
      width: 350px;
      height: 136px;
      border-radius: 13px;
      background: #fff;
      box-shadow: 0px 3px 26px rgba(110, 110, 110, 0.26);
      position: relative;
      cursor: pointer;
      .coming__soon {
        position: absolute;
        bottom: -17px;
        right: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        border-radius: 7px;
        padding: 8px 24px;
        background: linear-gradient(240deg, #00152f 0%, #035553 100%);
        display: none;
      }
      img {
        position: absolute;
        top: -20px;
        left: 10px;
        height: 150px;
        object-fit: cover;
      }
      .img-box {
        top: -40px;
        
        
        height: 150px;
      }
      .snake {
        top: -40px;
        height: 138px;
      }
      .globe {
        top: -20px;
        height: 108px;
      }
      h6 {
        font-weight: bold;
        font-size: 22px;
        text-align: left;
        color: #00152f;
        padding: 30px 24px 24px 160px;
        line-height: 30px;
        @include mq(768) {
          font-size: 18px;
        }
      }
      @include mq(992) {
        width: calc(50% - 24px);
        margin-bottom: 40px;
      }
      @include mq(768) {
        width: 100%;
        margin: 0;
        margin-bottom: 40px;
      }
    }
    .deactivate__box {
      background-color: #dcdcdc;
      cursor: no-drop;
      .coming__soon {
        display: unset;
      }
      img {
        filter: grayscale(1);
      }
    }
  }
}



.contact_container{
  position: absolute;
  top: 0;
  left: 0;
  z-index:1;
  transition: all 1.8s ease-out;
  width:100vw;
  height:100vh;
  background: rgb(81,136,74);
  background: linear-gradient(180deg, rgba(81,136,74,1) 0%, rgba(41,122,87,1) 63%, #091e17 100%);
  clip-path: circle(48px at calc(100% - 10px) calc(110% - 20px));
  display: flex;
  justify-content:center;
  align-items:center;
  pointer-events: none;

  @include mq(752){
    height: 1100px;
  }

  .email_validity{
    text-align: center;

    h3{
      font-size: 28px;
      color: red;
    }
  }

  .contact__form{
    width: 80%;
    height: 650px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    opacity: 0;
    transition: all 1s linear;

    @include mq(752){
      margin-top: -100px;
    }


    h2{
      color: white;
      font-size: 46px;
      @include mq(752){
        font-size: 32px;
      }
    }

    .form__container{
      width: 700px;
      @include mq(752){
        width: 100vw;
        display: flex;
        justify-content: center;
        padding: 40px;
      }
      .form__element{

        margin-bottom: 35px;
        
        input[type="text"] {
          width: 100%;
          background-color: $white;
          border-radius: 5px;
          border: 1px solid rgba($gray350, 0.37);
          padding: 15px 0 14px 25px;

          @include mq(752){
            width: 90%;
          }
        }

        textarea {
          width: 100%;
          background-color: $white;
          border-radius: 5px;
          border: 1px solid rgba($gray350, 0.37);
          padding: 15px 0 0 25px;
          resize: none;
          max-height: 197px;
          height: 197px;

          @include mq(752){
            width: 90%;
            height: 140px;
          }

        }

        label {
          color: $dark-blue-220;
          font-size: 22px;
          font-weight: 400;
          margin-bottom: 17px;
          color: #f0f0f0;
          position: relative;
          @include mq(752){
            font-size: 18px;
          }
          &.is__required {
            &::after {
              content: url("./../../images/icon/required.svg");
              position: absolute;
              top: 0;
              right: -13px;
            }
          }
        
        }
      }

      .button__container{
        display: flex;
        justify-content: center;
        width: 100%;

        button[type="submit"] {
          border-radius: 6px;
          background-image: linear-gradient(
            250deg
            , #f0f0f0 0%, #baebe6 100%);
          border : 3px solid #8a9e25;
          color: $green800;
          font-size: 18px;
          font-weight: 500;
          width: 200px;
          padding: 12px 20px;
        }
      }
    }

  }

  .contact__form.active{
    opacity: 1;
  }
  
}

.contact__us{
  cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 15px;
    right: 20px;
    z-index: 2147483000;
    width: 55px;
    height: 55px;
    border: none;
    border-radius: 50%;
    background: #f0f0f0;
    background-image: url("../../images/icon/feedback-snake.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 40px;
    box-shadow: 0 1px 6px 0 rgb(0 0 0 / 6%), 0 2px 32px 0 rgb(0 0 0 / 16%);
  
}

.contact_container.active{
  clip-path: circle(2500px at 95% 95%);
  pointer-events: initial;
}


.modal-content {
  border-radius: 18px;
  border: none;
}
.home__modal {
  padding: 32px;
  border-radius: 18px;
  border: none;
  @include mq(992) {
    padding: 32px 16px;
  }
  .modal__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    h6 {
      font-weight: 600;
      font-size: 18px;
      color: #00152f;
      @include mq(992) {
        font-size: 16px;
      }
    }
    .close__modal {
      padding: 4px;
      cursor: pointer;
    }
  }
  .modal__content {
    padding-top: 60px;
    padding-bottom: 60px;
    h6 {
      font-weight: normal;
      font-size: 22px;
      color: #002c63;
      font-weight: normal;
      margin-bottom: 22px;
      @include mq(992) {
        font-size: 18px;
      }
    }
    input {
      width: 100%;
      height: 57px;
      border-radius: 5px;
      background: #fff;
      border: 1px solid rgba(112, 112, 112, 0.37);
      padding-left: 24px;
      display: block;
      &::placeholder {
        font-weight: normal;
        font-size: 18px;
        color: #989696;
      }
    }
  }
  .modal__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      padding: 16px 32px;
      font-weight: 500;
      font-size: 18px;
      text-align: center;
      border-radius: 6px;
      color: #7a7a7a;
    }
    .submit {
      color: #fff;
      background: linear-gradient(250deg, #001728 0%, #046f65 100%);
    }
    .return {
      border: 1px solid #868686;
    }
  }
}
