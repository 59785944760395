$font-main: Prompt;
$font-size: 13px;

$black: #000;
$white: #fff;

$dark-blue-200: #023b41;
$dark-blue-220: #002c63;
$dark-blue-250: #012857;
$dark-blue-300: #002249;
$dark-blue-400: #00152f;

$green50: #40ca8f;
$green100: #0ca789;
$green300: #00ae63;
$green400: #039757;
$green800: #15503d;

$purple180: #5f65a6;
$purple200: #686eb6;

$orange200: #d38b4e;
$orange300: #f24726;

$red100: #E32636;

$gray100: #f1f1f1;
$gray200: #e9e9e9;
$gray350:#707070;
$gray400: #7a7a7a;

