@mixin mq($size) {
    @media only screen and (max-width: $size+px) {
        @content;
    }
}
@mixin mqm($size) {
    @media only screen and (min-width: $size+px) {
        @content;
    }
}

@mixin mqh($size) {
    @media only screen and (max-height: $size+px) {
        @content;
    }
}
/* Function for PX to REM */
@function rem($pixels) {
    @return #{$pixels / 16}rem;
}
