body {
  font-size: $font-size;
  direction: ltr;
  font-family: $font-main;
  overflow-x: hidden;
  position: relative;
  a {
    text-decoration: none;
  }
  ul,
  ol {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
  button {
    border: none;
    padding: 0;
    background-color: transparent;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0;
  }
  p {
    margin-bottom: 0;
  }
}
.overlay {
  display: none;
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: transparent;
  backdrop-filter: blur(3px);
  --webkit-backdrop-filter: blur(3px);
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 7777;
}
.overlay__active{
  display: block;
}
