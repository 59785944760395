header {
  width: 100vw;
  position: relative;
  z-index: 100;
  @include mq(992) {
    background: linear-gradient(325deg, #baaf28 0%, #046c63 100%);
    padding-bottom: 20px;
  }
  .header__bg {
    position: absolute;
    right: 0;
    top: -10px;
    width: 100%;
    z-index: -1;
    @include mqm(1800) {
      top: -50px;
    }
    @include mq(1200) {
      top: -0px;
    }
    @include mq(1100) {
      // top: -20px;
    }
    @include mq(992) {
      display: none;
    }
  }
  .wrapper {
    .content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding-top: 16px;

      .userAction{
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 20px;
      }

      a {
        .logo {
          width: 69px;
          height: 69px;
          border-radius: 40px;
          background: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            height: 43px;
          }
        }
      }
    }
  }
}
