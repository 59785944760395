@keyframes Loading {
  from {
    left: -110px;
  }
  to {
    left: 430px;
  }
}

@keyframes random-moves {
  from {margin-left: 0 ; margin-top:0; }
  to {margin-left: 220+vw; margin-top: random(50)+vh}
}

@keyframes float {
	0% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
	50% {
		box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
		transform: translatey(-4px);
	}
	100% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
}

@keyframes pulse {
  0% {
    box-shadow: #50884a  0 0 0 0;
  }
  100% {
    box-shadow: #01250700 0 0 0 20px;
  }
}

@keyframes loader {

	0% {
    width: 0;}

	100% {
		width: 100%;
	}

}

@keyframes text-animation {

  0% {
    opacity: 0;}

	100% {
		opacity: 1;
	}
  
}


section.guide__line
{
  
  padding-top: 70px;
  > .wrapper
  {
   > .content
   {
    .Smooth__screen{
      width: 100vw;
      height: 80vh;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;

      > h2 {
        font-size: 45px;
        letter-spacing: 1px;
        width: 900px;
        color:#00152f;

        @include mq(572){
          font-size: 22px;
          width: 80%;
        }
      }
      
      .map__guide{
        height: 550px;
        width: 720px;
        text-align: left;
        margin-top: 50px;
        color:#00152f;

        @include mq(572){
          width: 80%;
        }

        > h3 
        {
          font-size: 18px;
          margin-top: 10px;
          font-weight: 600;
        }


        > ul{
          padding: 30px 15px;
          font-size: 15px;
          font-weight: 500;
          

          > li{
            padding: 5px;
            font-size: 16px;
            letter-spacing: 1px;
          }
        }
        
      }

      .progress-button {
        border-radius:10px;
        height:80px;
        margin-top: 30px;
        margin-bottom: 50px;
        border-radius:20px;
        display:flex;
        justify-content:center;
        align-items:center;
        overflow: hidden;
        width: 100%;
        animation : pulse 1.2s Cubic-Bezier(0.5,0.8,0.5,0.8) 4.5s;
          
        
          span {
            display: block;
             width:700px;
             height:59px;
            padding:0px;
            border-radius:20px;
          }

          button{
            width:700px;
            height:60px;
            padding:0px;
            border:none;
            display: flex;
            border-radius: 20px;

            h2{
              width: 100%;
              height: 100%;
              display: flex;
              font-size: 25px;
              justify-content: center;
              align-items: center;
              animation: text-animation 0.8s Cubic-Bezier(0.5,0.8,0.5,0.8);
            }

          }

        .bar {
          background: rgba(0, 0, 0, 0.3);
          animation : pulse 1s Cubic-Bezier(0.5,0.8,0.5,0.8) 4.5s;

          .progress {
            animation: loader 4.5s forwards Cubic-Bezier(0.5,0.8,0.5,0.8) ;
            // Change the animation fill mode 'infinite' to 'forwards' to stop the animation from repeating.
          background: rgb(41,122,88);
          background: linear-gradient(90deg, rgba(41,122,88,1) 0%, rgba(75,134,64,1) 100%);
            color: #fff;
            /*padding: $bar-size;*/
            width: 0;
          } 
         
        }
      
      }

      .load__section{
        width: 700px;
        text-align: center;
      }

      .loading {
        display: flex;
        justify-content: center;
        .loading__parent {
          width: 427px;
          height: 12px;
          background-color: $gray200;
          border-radius: 6px;
          position: relative;
          margin-bottom: 40px;
          overflow: hidden;
          .loading__child {
            position: absolute;
            width: 110px;
            height: inherit;
            border-radius: inherit;
            background: linear-gradient(#046e64 0%, #00182a 100%);
            border: 1px solid rgba(112, 112, 112, 0.19);
            left: 200px;
            animation: Loading 2s infinite ease-out;
            animation-direction: revert;
          }
        }
        .modal_content {
          background-color: blueviolet;
        }
        .notice {
          color: $dark-blue-400;
          font-size: 15px;
          font-weight: 400;
        }
      }

    }
    .upload__biteimage__step{

     .button__container
     {
       width: 100vw;
       display: flex;
       justify-content: center;
      button[type="submit"] {
        border-radius: 6px;
        background-image: linear-gradient(
          250deg
          , #50884a 0%, #297957 100%);
        border: 2px solid $green100;
        color: $white;
        font-size: 18px;
        font-weight: 500;
        width: 300px;
        padding: 20px 50px;
        margin-bottom: 20px;
        margin-top:-70px
      }
     }


      .image__identification__uploader{

        display: flex;
        flex-direction: column;
        justify-content:flex-start;
        align-items: center;
        height:80vh;

        .close__icon
        {
          position: absolute;
          top: 20%;
          left: 90%;
          height: 40px;
          width: 40px;
          cursor: pointer;

          @include mq(576)
          {
            left:85%;
          }
        }
        
        @include mq(576) {
          height: 100vh;
          margin: 0;
          padding-top: 0;
        }
        >.head__text{
          text-align: center;
          padding-top: 50px;
          width: 60%;
          margin-bottom: 50px;
          h2{
          font-size: 37px;
          color: #00152f;
          font-weight: 500;
            @include mq(576) {
            font-size: 24px;
            }
          }
          @include mq(576) {
            padding-top: 0px;
          }
        }

        input[type=file]{
          width: 450px;
          height: 450px;
          position: absolute;
          top: 550px;
          left: 50%;
          transform: translate(-50%, -50%);
          opacity:0;
          z-index: 10;
          cursor: pointer;
        }
        //tablet resonsive
        .ciclegraph , .before__upload , .after__upload , .input[type=file]{
          @include mq(1100)
          {
            margin-top: 40px;
          }

          @include mqh(850){
            margin-bottom: 80px;
          }

          @include mqh(850){
            margin-bottom: 180px;
          }


        }

        

        .ciclegraph {
          width: 450px;
          height: 450px;
          position: absolute;
          top: 550px;
          left: 50%;
          transform: translate(-50%, -50%);
          cursor: pointer;

          @include mq(576) {
            width: 280px;
            height: 280px;
          }

          @include mqh(850) {
            width: 280px;
            height: 280px;
          }
          
        }
        
        .ciclegraph:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          border: 2px solid #17513d;
          width: calc(100% - 2px * 2);
          height: calc(100% - 2px * 2);
          border-radius: 50%;
        }
        
        .ciclegraph .circle {
          position: absolute;
          top: 220px;
          left: 50%;
          width: 150px;
          height: 100px;

          margin-left: calc(-165px / 2);
          margin-top: calc(-120px / 2);
          
          background: #17513d;
          @include mq(576) {
            display: none;
          }

          @include mqh(820){
          margin-left: calc(-165px / 2);
          margin-top: calc(-290px / 2);
          }

          
        }
        
        .ciclegraph .c1 {
          background: url("../../images/picture/red-snake.png");
          background-position: center;
          background-size: cover;
        }
        
        .ciclegraph .c2 {
          background: url("../../images/picture/red-hospital.png");
          background-position: center;
          background-size: cover;
        }

        .ciclegraph .c3 {
          background: url("../../images/picture/report.png");
          background-position: center;
          background-size: cover;
        }
        
        
        .before__upload {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 380px;
          height: 380px;
          border-radius: 50%;
          background: #17513d;
          position: absolute;
          top: 550px;
          left: 50%;
          transform: translate(-50%, -50%);
          cursor: pointer;

          @include mq(576) {

            width: 280px;
            height: 280px;
          }
        }
        
        .after__upload {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 380px;
          height: 380px;
          border-radius: 50%;
          background: #cc8a0f;
          position: absolute;
          top: 550px;
          left: 50%;
          transform: translate(-50%, -50%);
          cursor: pointer;

          @include mq(576) {
            width: 280px;
            height: 280px;
          }

          img.uploaded__image{
            width: 100%;
            height: 100%;
            position:absolute;
            border-radius: 50%;
            animation: 2s pulse infinite;
          }

        }

        .dragNdrop {
          width: 320px;
          height: 320px;
          padding: 20px;
          border-radius: 46%;
          border: 3px dashed #012857;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-end;
          background: #f1f1f1;
          cursor: pointer;

          @include mq(576) {
            width: 250px;
            height: 250px;
          }
        }
        
        .dragNdrop > p {
          font-size: 28px;
          text-align: center;
          margin-bottom: 15px;
          @include mq(576) {
            font-size: 16px;
          }
        }
      }
    }

    section.risk__location{

      text-align: center;
      height: auto;
          .element__container{
            position: absolute;
            width: 100vw;
            height: 70vh;
            z-index: -1;

            .element{
              animation: 15s ease-out 1s infinite reverse both running random-moves;
            }

          }

          @for $i from 1 through 36 {
            div.element:nth-child(#{$i}){
              color: red;
              top:random(90)+vh;
              left:random(95)-100+vw;
              width: 50px;
              height: 50px;
              border-radius: 50%;
              background: hsl($i * 10, 50%, 50%);
              position: fixed;
            }
          }

          h2 {
            font-size: 37px;
            font-weight: 500;
            color: $dark-blue-400;
            margin-bottom: 40px;
            color:#00152f;
          }

          .button__container_non_relv
          {
            display: flex;
            justify-content: center;
            margin-bottom: 30px; 
            margin-top: 10px; 
            text-align: center;          

            button[type="submit"] {
              background: #046f65;
              margin: 5px;
              color: $white;
              font-size: 16px;
              font-weight: 500;
              width: 350px;
              height: 50px;
              padding: 0px;
              }

          }

  
          button {
            padding: 20px 5px;
            border-radius: 6px;
            font-size: 18px;
            font-weight: 500;
          }
          .orange__button {
            border: 2px solid $orange200;
            color: $orange200;
          }
          .blue__button {
            border: 2px solid $purple200;
            color: $purple200;
          }
          .green__button {
            width: 100%;
            background-color: $green300;
            border: 2px solid $green400;
            color: $white;
          }
  
          .toxic
          {
            margin-top: 200px;
            width: 100%;
          }
  
          .guide {
            margin-bottom: 0px;
            margin-top: 15px;
            @include mq(768) {
              margin-bottom: 40px;
            }
  
            
            .items {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 40px;
              @include mq(768) {
                flex-direction: column;
                align-items: flex-start;
                padding: 14px;
              }
              .item {
                margin-left: 72px;
                position: relative;
                display: flex;
                align-items: center;
                &:first-child {
                  margin-left: 0;
                }
                @include mq(768) {
                  margin-left: 22px !important;
                  margin-bottom: 15px;
                }
                &::before {
                  content: "";
                  position: absolute;
                  width: 22px;
                  height: 22px;
                  border-radius: 50%;
                  left: -28px;
                }
                &:nth-child(1) {
                  &::before {
                    background-color: $green100;
                  }
                }
                &:nth-child(2) {
                  &::before {
                    background-color: $orange300;
                  }
                }
                &:nth-child(3) {
                  &::before {
                    background-color: $orange200;
                  }
                }
                span {
                  font-size: 18px;
                  font-weight: 400;
                }
              }
            }
          }
          .not__found{
            width: 100vw;
            height: 85vh;
            margin-top: -100px;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            z-index: 1;

            .no__species{
              width: 600px;
              height: 450px;
              background-image: linear-gradient(
              225deg
              , #50884a 0%, #297957 100%);
              background-position: center;
              background-size: cover;
              border-radius: 2%;
              display: flex;
              border: 1px black solid;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              /*animation: float 8s ease-in-out infinite;*/

              > h2{
                font-size: 25px;
                color: #00182a;
                margin-top: 50px;
                background: rgb(221 149 148);
              }

              .header{
                /*background: url("../../images/picture/snake.png");*/
                background-size: cover;
                background-position: center;
                height: 200px;
                width: 200px;
              }

              
            }

          }
          .loading {
            display: flex;
            justify-content: center;
            .loading__parent {
              width: 427px;
              height: 12px;
              background-color: $gray200;
              border-radius: 6px;
              position: relative;
              margin-bottom: 40px;
              overflow: hidden;
              .loading__child {
                position: absolute;
                width: 110px;
                height: inherit;
                border-radius: inherit;
                background: linear-gradient(#046e64 0%, #00182a 100%);
                border: 1px solid rgba(112, 112, 112, 0.19);
                left: 200px;
                animation: Loading 1s infinite ease-out;
                animation-direction: revert;
              }
            }
            .modal_content {
              background-color: blueviolet;
            }
            .notice {
              color: $dark-blue-400;
              font-size: 15px;
              font-weight: 400;
            }
          }
  
          .suggestions {
            > .items {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding:50px 50px;
              margin:0px 50px;
              border:0.5px solid rgba(#707070, 0.3);
              box-shadow: rgba(50, 50, 93, 0.25) 0px 10px 60px -20px inset, rgba(0, 0, 0, 0.3) 0px 18px 16px -18px inset;
              
              .class__not__found{
                width: 350px;
                height: 40px;
                color: whitesmoke;
                background:#17513d;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom:30px ;
  
                p{
                  font-size: 16px;
                }
              }
              > .item {
                width: 100%;
                padding: 10px 14px 36px 39px;
                background: $white;
                filter: drop-shadow(0px 0px 25px rgba(37, 37, 37, 0.15));
                margin-bottom: 33px;
                border-radius: 12px;
                cursor: pointer;
                &.border-green {
                  border-left: 9px solid $green100;
                }
                &.border-orange {
                  border-left: 9px solid $orange200;
                }
                &.border-dark-orange {
                  border-left: 9px solid $orange300;
                }
                .top {
                  display: flex;
                  justify-content: space-between;
                  margin-bottom: 20px;
                  .left {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    .type__and__name {
                      display: flex;
                      align-items: center;
                      margin-bottom: 17px;
                      .type__icon {
                        margin-right: 12px;
                        svg {
                        }
                      }
                      .type__name {
                        h4 {
                          font-size: 24px;
                          font-weight: 500;
                          color: $dark-blue-400;
                        }
                      }
                    }
                    .tip {
                      span {
                        position: relative;
                        margin-left: 22px;
                        display: flex;
                        align-items: center;
                        &::before {
                          content: "";
                          position: absolute;
                          width: 10px;
                          height: 10px;
                          border-radius: 50%;
                          background-color: $dark-blue-400;
                          left: -22px;
                        }
                      }
                    }
                  }
                  .right {
                    .percentage__similarity {
                      margin: 14px 14px 0 0;
                      .box {
                        position: relative;
                        transform: scale(1.5);
                        svg {
                          width: 33px;
                          height: 33px;
                          position: relative;
                          transform: rotate(-90deg);
  
                          circle {
                            width: 30px;
                            height: 30px;
                            fill: none;
                            stroke-width: 3;
                            stroke: #000;
                            transform: translate(2px, 2px);
                            -webkit-transform: translate(2px, 2px);
                            -moz-transform: translate(2px, 2px);
                            -ms-transform: translate(2px, 2px);
                            -o-transform: translate(2px, 2px);
                            stroke-dasharray: 95;
                            stroke-dashoffset: 95;
  
                            &:first-child {
                              stroke-dashoffset: 0;
                              stroke: rgba(#707070, 0.3);
                            }
                            &:last-child {
                              stroke: green;
                            }
                          }
                        }
                        .purple_circle {
                          circle {
                            &:last-child {
                              stroke: $purple200;
                            }
                          }
                        }
                        .number {
                          position: absolute;
                          right: 50%;
                          width: 100%;
                          text-align: center;
                          top: 50%;
                          transform: translate(50%, -50%);
                          -webkit-transform: translate(50%, -50%);
                          -moz-transform: translate(50%, -50%);
                          -ms-transform: translate(50%, -50%);
                          -o-transform: translate(50%, -50%);
                          font-size: 10px;
                          color: $dark-blue-400;
                        }
                      }
                    }
                  }
                }
                .content {
                  .snake__images {
                    .items {
                      display: flex;
                      flex-wrap: wrap;
                      .item {
                        margin-right: 21px;
                        width: 180px;
                        height: 124px;
                        border-radius: 7px;
                        overflow: hidden;
                        @include mq(1400) {
                          margin-bottom: 10px;
                        }
                        img {
                          width: 100%;
                          height: 100%;
                          object-fit: cover;
                        }
                      }
                    }
                  }
                }
                
              }
            }
          }
  
     }
  
    
  }

   }
   .button__group
   {
     display: flex;
     justify-content: center;
     align-items: center;
   }
  }
  


section.question__box{

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 80vh;

  span{
    color: #851919;
    font-weight: bold;
  }

 > .question__quote{
   background:white;
   width: 100vw;
   height: 100px;
   display: flex;
   justify-content: center;
   align-items: center;
   @include mq(572){
      padding: 5px;
   }
    h2{
      font-size: 54px;
      color: #001728;
      font-weight: 500;
      @include mq(1300){
        font-size: 32px;
        text-align: center;
      }
      @include mq(576){
        font-size: 25px;
        text-align: center;
      }
    }
 }

 > .question__response{
   width: 85vw;
   height: 200px;
   padding-top: 0px;
   display: flex;
   justify-content: center;
   align-items: center;
   
   > svg{
     cursor: pointer;
     margin: 0px 30px;

     > path{
      pointer-events: auto;}
   } 
  
   }

   .map__wrapper
   {
     display: flex;
     flex-direction: column;
     justify-content: space-evenly;
     align-items: center;
     height: 65vh;
    .map__container{
      width: 55vw;
      height: 50vh;
      background: black;
      margin-top:-10px;
      border:2px solid white;
      border-radius: 5px;
      @include mq(572){
        width: 95vw;
      }
    }
  
    button[type="submit"] {
      border-radius: 6px;
      background-image: linear-gradient(
        250deg
        , #001728 0%, #046f65 100%);
      border: 2px solid $green400;
      color: $white;
      font-size: 18px;
      font-weight: 500;
      width: 250px;
      padding: 20px 50px;
    }
   }

  }