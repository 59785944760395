@keyframes Loading {
  from {
    left: -110px;
  }
  to {
    left: 430px;
  }
}
section.image__identification {
  padding-top: 70px;
  > .wrapper {
    > .content {
      .upload__image__step {
        .close__icon
        {
          position: absolute;
          top: 13%;
          left: 92%;
          height: 40px;
          width: 40px;
          cursor: pointer;

          @include mq(572){
            left: 88%;
            top: 9%;
          }
        }
        .image__identification__uploader {
          display: flex;
          flex-direction: column;
          align-items: center;
          .head__text {
            margin-bottom: 65px;
            text-align: center;
            h2 {
              font-size: 37px;
              color: $dark-blue-400;
              font-weight: 500;
            }
            h5 {
              margin-top: 21px;
            }
          }

          .upload__box {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 420px;
            overflow: hidden;
            @include mq(576) {
              width: 100%;
            }
            position: relative;
            background-color: $gray100;
            border: 3px dashed $dark-blue-250;
            border-radius: 26px;
            padding: 35px 0;
            margin-bottom: 24px;
            h4 {
              font-size: 28px;
              font-weight: 400;
              color: $dark-blue-300;
              max-width: 200px;
              text-align: center;
              margin-bottom: 20px;
            }
            input[type="file"] {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              opacity: 0;
              z-index: 1;
            }
            .after__upload {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              z-index: 2;
              img {
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 3;
              }
              .image__overlay {
                z-index: 4;
                position: absolute;
                width: 100%;
                height: 100%;
                background: linear-gradient(
                  rgba(0, 21, 47, 0.23) 0%,
                  #00152f 100%
                );
              }
              .information {
                position: absolute;
                bottom: 17px;
                left: 19px;
                z-index: 5;
                .location {
                  span {
                    font-size: 18px;
                    font-weight: 400;
                    color: $white;
                    margin-bottom: 200px;
                    &:first-child {
                      margin-right: 16px;
                    }
                  }
                }
                .size {
                  span {
                    font-size: 18px;
                    font-weight: 400;
                    color: $white;
                  }
                }
              }
            }
          }
          .upload__actions {
            width: 420px;
            margin-bottom: 62px;
            @include mq(576) {
              width: 100%;
            }
            .two__button {
              margin-bottom: 15px;
              display: flex;
              justify-content: space-between;
              button {
                width: calc(50% - 18px);
              }
            }
            button {
              padding: 20px 0;
              border-radius: 6px;
              font-size: 18px;
              font-weight: 500;
            }
            .orange__button {
              border: 2px solid $orange200;
              color: $orange200;
            }
            .blue__button {
              border: 2px solid $purple200;
              color: $purple200;
            }
            .green__button {
              width: 100%;
              background-color: rgba(3, 65, 70, 0.95);
              border: 2px solid $green400;
              color: $white;
            }
          }
          .upload__hints {
            @include mq(768) {
              padding-left: 20px;
            }
            .items {
              .item {
                position: relative;
                margin-bottom: 25px;
                display: flex;
                align-items: center;
                span {
                  font-size: 16px;
                  font-weight: 400;
                  color: $dark-blue-400;
                }
                &::before {
                  content: "";
                  position: absolute;
                  width: 11px;
                  height: 11px;
                  border-radius: 50%;
                  left: -17px;
                  background-color: $dark-blue-400;
                }
              }
            }
          }
        }
        .to__know {
          text-align: center;
          margin:35px 0px;
          @include mq(1200) {
            width: 50%;
            margin: auto;
            text-align: center;
          }
          @include mq(992) {
            width: 100%;
            text-align: center;
          }
          span
          {
            text-align: center;
            font-size: 17px;
            
          }
        
          .items {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: -20px;
            @include mq(1200) {
              flex-direction: column;
              align-items: center;
            }
            
            .item {
              display: flex;
              align-items: center;
              margin:0px 25px;
              .text {
                font-size: 18px;
                font-weight: 400;
                color: $dark-blue-400;
              }
            }
          }
        }
      }
      
      .identification__step {
        text-align: center;
        h2 {
          font-size: 37px;
          font-weight: 500;
          color: $dark-blue-400;
          margin-bottom: 40px;
        }

        button {
          padding: 20px 5px;
          border-radius: 6px;
          font-size: 18px;
          font-weight: 500;
        }
        .orange__button {
          border: 2px solid $orange200;
          color: $orange200;
        }
        .blue__button {
          border: 2px solid $purple200;
          color: $purple200;
        }
        .green__button {
          width: 100%;
          background-color: $green300;
          border: 2px solid $green400;
          color: $white;
        }

        .toxic
        {
          margin-top: 200px;
          width: 100%;
        }

        .guide {
          margin-bottom: 88px;
          margin-top: 15px;
          @include mq(768) {
            margin-bottom: 40px;
          }

          
          .items {
            display: flex;
            align-items: center;
            justify-content: center;
            @include mq(768) {
              flex-direction: column;
              align-items: flex-start;
              padding: 14px;
            }
            .item {
              margin-left: 72px;
              position: relative;
              display: flex;
              align-items: center;
              &:first-child {
                margin-left: 0;
              }
              @include mq(768) {
                margin-left: 22px !important;
                margin-bottom: 15px;
              }
              &::before {
                content: "";
                position: absolute;
                width: 22px;
                height: 22px;
                border-radius: 50%;
                left: -28px;
              }
              &:nth-child(1) {
                &::before {
                  background-color: $green100;
                }
              }
              &:nth-child(2) {
                &::before {
                  background-color: $orange300;
                }
              }
              &:nth-child(3) {
                &::before {
                  background-color: $orange200;
                }
              }
              span {
                font-size: 18px;
                font-weight: 400;
              }
            }
          }
        }
        .loading {
          display: flex;
          justify-content: center;
          .loading__parent {
            width: 427px;
            height: 12px;
            background-color: $gray200;
            border-radius: 6px;
            position: relative;
            margin-bottom: 40px;
            overflow: hidden;
            .loading__child {
              position: absolute;
              width: 110px;
              height: inherit;
              border-radius: inherit;
              background: linear-gradient(#046e64 0%, #00182a 100%);
              border: 1px solid rgba(112, 112, 112, 0.19);
              left: 200px;
              animation: Loading 1s infinite ease-out;
              animation-direction: revert;
            }
          }
          .modal_content {
            background-color: blueviolet;
          }
          .notice {
            color: $dark-blue-400;
            font-size: 15px;
            font-weight: 400;
            font-style: italic;
          }
        }

        .suggestions {
          > .items {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            
            .class__not__found{
              width: 350px;
              height: 50px;
              color: whitesmoke;
              background:#046f65;
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 10px;
              margin-bottom:30px ;

              p{
                font-size: 16px;
              }
            }
            > .item {
              width: 100%;
              padding: 10px 14px 36px 39px;
              background: $white;
              filter: drop-shadow(0px 0px 25px rgba(37, 37, 37, 0.15));
              margin-bottom: 33px;
              border-radius: 12px;
              cursor: pointer;
              &.border-green {
                border-left: 9px solid $green100;
              }
              &.border-orange {
                border-left: 9px solid $orange200;
              }
              &.border-dark-orange {
                border-left: 9px solid $orange300;
              }
              .top {
                display: flex;
                justify-content: space-between;
                margin-bottom: 20px;
                .left {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  .type__and__name {
                    display: flex;
                    align-items: center;
                    margin-bottom: 17px;
                    .type__icon {
                      margin-right: 12px;
                      svg {
                      }
                    }
                    .type__name {
                      h4 {
                        font-size: 24px;
                        font-weight: 500;
                        color: $dark-blue-400;
                      }
                    }
                  }
                  .tip {
                    span {
                      position: relative;
                      margin-left: 22px;
                      display: flex;
                      align-items: center;
                      &::before {
                        content: "";
                        position: absolute;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background-color: $dark-blue-400;
                        left: -22px;
                      }
                    }
                  }
                }
                .right {
                  .percentage__similarity {
                    margin: 14px 14px 0 0;
                    .box {
                      position: relative;
                      transform: scale(1.5);
                      svg {
                        width: 33px;
                        height: 33px;
                        position: relative;
                        transform: rotate(-90deg);

                        circle {
                          width: 30px;
                          height: 30px;
                          fill: none;
                          stroke-width: 3;
                          stroke: #000;
                          transform: translate(2px, 2px);
                          -webkit-transform: translate(2px, 2px);
                          -moz-transform: translate(2px, 2px);
                          -ms-transform: translate(2px, 2px);
                          -o-transform: translate(2px, 2px);
                          stroke-dasharray: 95;
                          stroke-dashoffset: 95;

                          &:first-child {
                            stroke-dashoffset: 0;
                            stroke: rgba(#707070, 0.3);
                          }
                          &:last-child {
                            stroke: green;
                          }
                        }
                      }
                      .purple_circle {
                        circle {
                          &:last-child {
                            stroke: $purple200;
                          }
                        }
                      }
                      .number {
                        position: absolute;
                        right: 50%;
                        width: 100%;
                        text-align: center;
                        top: 50%;
                        transform: translate(50%, -50%);
                        -webkit-transform: translate(50%, -50%);
                        -moz-transform: translate(50%, -50%);
                        -ms-transform: translate(50%, -50%);
                        -o-transform: translate(50%, -50%);
                        font-size: 10px;
                        color: $dark-blue-400;
                      }
                    }
                  }
                }
              }
              .content {
                .snake__images {
                  .items {
                    display: flex;
                    flex-wrap: wrap;
                    .item {
                      margin-right: 21px;
                      width: 180px;
                      height: 124px;
                      border-radius: 7px;
                      overflow: hidden;
                      @include mq(1400) {
                        margin-bottom: 10px;
                      }
                      img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                      }
                    }
                  }
                }
              }
              
            }
          }
        }
      }
      .result__step {
        display: flex;
        justify-content: space-between;
        @include mq(992) {
          flex-direction: column-reverse;
        }
        .left {
          width: calc(50% - 30px);
          @include mq(992) {
            width: 100%;
          }
          .snake__info {
            display: flex;
            flex-direction: column;
            .title {
              display: flex;
              align-items: center;
              margin-bottom: 30px;
              svg {
                margin-right: 12px;
              }
              h3 {
                font-size: 33px;
                font-weight: 500;
                color: $dark-blue-400;
                font-style: italic;
              }
            }
            .fine__information {
              display: flex;
              align-items: center;
              margin-bottom: 18px;
              .notice {
                margin-right: 23px;
                span {
                  font-size: 17px;
                  font-weight: 400;
                  color: $dark-blue-400;
                  position: relative;
                  display: flex;
                  align-items: center;
                  margin-left: 16px;
                  &::before {
                    content: "";
                    position: absolute;
                    width: 8px;
                    height: 8px;
                    left: -16px;
                    border-radius: 50%;
                    background-color: $dark-blue-400;
                  }
                }
              }
              .location {
                svg {
                  margin-right: 7px;
                }
                span {
                  font-size: 17px;
                  font-weight: 400;
                  color: $dark-blue-400;
                }
              }
            }
            .description {
              p {
                font-size: 15px;
                font-weight: 400;
                line-height: 40px;
                color: $gray400;
              }
            }
          }
        }
        .right {
          width: calc(50% - 30px);
          @include mq(992) {
            width: 100%;
            margin-bottom: 135px;
          }

          .guide {
            margin-bottom: 88px;
            @include mq(768) {
              margin-bottom: 40px;
            }
            .items {
              display: flex;
              align-items: center;
              justify-content: center;
              @include mq(768) {
                flex-direction: column;
                align-items: flex-start;
                padding: 14px;
              }
              .item {
                margin-left: 72px;
                position: relative;
                display: flex;
                align-items: center;
                &:first-child {
                  margin-left: 0;
                }
                @include mq(768) {
                  margin-left: 22px !important;
                  margin-bottom: 15px;
                }
                &::before {
                  content: "";
                  position: absolute;
                  width: 22px;
                  height: 22px;
                  border-radius: 50%;
                  left: -28px;
                }
                &:nth-child(1) {
                  &::before {
                    background-color: $green100;
                  }
                }
                &:nth-child(2) {
                  &::before {
                    background-color: $orange300;
                  }
                }
                &:nth-child(3) {
                  &::before {
                    background-color: $orange200;
                  }
                }
                span {
                  font-size: 18px;
                  font-weight: 400;
                }
              }

              .item1 {
                margin-left: 72px;
                position: relative;
                display: flex;
                align-items: center;
                &:first-child {
                  margin-left: 0;
                }
                
                @include mq(768) {
                  margin-left: 22px !important;
                  margin-bottom: 15px;
                }
                &::before {
                  content: "";
                  position: absolute;
                  width: 22px;
                  height: 22px;
                  border-radius: 50%;
                  left: -28px;
                  background-color: #a52714;
                  //border: 0.5px red solid;
                  
                }

                span
                {
                  font-size: 18px;
                  font-weight: 400;
                }
              
              
            }
            .item2 {
              margin-left: 72px;
              position: relative;
              display: flex;
              align-items: center;
              
              &:first-child {
                margin-left: 0;
              }
              
              @include mq(768) {
                margin-left: 22px !important;
                margin-bottom: 15px;
              }
              &::before {
                content: "";
                position: absolute;
                width: 22px;
                height: 22px;
                border-radius: 50%;
                left: -28px;
                background-color: blueviolet;
              }

              span
              {
                font-size: 18px;
                font-weight: 400;
              }
            
            
          }

          .item3 {
            margin-left: 72px;
            position: relative;
            display: flex;
            align-items: center;

            &:first-child {
              margin-left: 0;
            }
            
            @include mq(768) {
              margin-left: 22px !important;
              margin-bottom: 15px;
            }
            &::before {
              content: "";
              position: absolute;
              width: 22px;
              height: 22px;
              border-radius: 50%;
              left: -28px;
              background-color: #097139;
            }

            span
            {
              font-size: 18px;
              font-weight: 400;
            }
          
          
        }

        .WholeLocation {
          margin-left: 72px;
          position: relative;
          display: flex;
          align-items: center;

          &:first-child {
            margin-left: 0;
          }
          
          @include mq(768) {
            margin-left: 22px !important;
            margin-bottom: 15px;
          }
          &::before {
            content: "";
            position: absolute;
            width: 22px;
            height: 22px;
            border-radius: 50%;
            left: -28px;
            background-color: #a3a3a3;
            opacity: 0.5;
            border: 0.5px #a3a3a3 solid;
          }

          span
          {
            font-size: 18px;
            font-weight: 400;
          }
        
        
      }
          }
          }
          .gallery {
            margin-bottom: 27px;
            .master__image {
              width: 100%;
              height: 362px;
              border-radius: 7px;
              overflow: hidden;
              margin-bottom: 12px;
              img {
                object-fit: cover;
                width: 100%;
                height: 100%;
              }
            }
            .gallery__component {
              .gallery__items {
                display: flex;
                flex-wrap: wrap;
                .gallery__item {
                  width: 92px;
                  height: 73px;
                  margin-right: 17px;
                  margin-bottom: 10px;
                  border-radius: 7px;
                  overflow: hidden;
                  cursor: pointer;
                }
                .more__items {
                  width: 92px;
                  height: 73px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background-color: $gray200;
                  border-radius: 7px;
                  overflow: hidden;
                  margin-right: 17px;
                  margin-bottom: 10px;
                  cursor: pointer;
                }
              }
            }
          }
          .location {
            height: 420px;
            max-height: 400px;
            margin: 10px;
          }
        }

        
        .tell__us__feedback {
          position: fixed;
          padding: 24px 50px;
          width: 100%;
          left: 0;
          bottom: 0;
          z-index: 10;
          background-color: $green800;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding: 50px;
          @include mq(450) {
            flex-direction: column;
          }
          h4 {
            margin-right: 20px;
            @include mq(450) {
              margin-right: 0;
              margin-bottom: 20px;
            }
            color: $white;
            font-size: 22px;
            font-weight: 500;
          }
          button {
            background-color: $white;
            border-radius: 9px;
            font-size: 18px;
            font-weight: 500;
            color: $dark-blue-400;
            padding: 10px 20px;
            margin-right: 430px;
          }
          @include mq(450) {
            margin-right:175px;
          }
        }

          .guide__action__group
          {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            height: 0;
            color: #f0f0f0;
            padding: 10px;

            img{
              cursor: pointer;
              margin: 0px 4px;;
            }
          }

          h2{
            font-size: 24px;
          }

        .action__group{
          width: 100%;
          left: 0;
          position: fixed;
          display: flex;
          margin: 0 55px;
          justify-content: space-around;
          align-items: center;
          padding: 50px;
          @include mq(450) {
            flex-direction: column;
            width: 100%;
            margin: 0 20px;
          }
          @include mq(450) {
            padding: 0px;
            font-size: 15;
            margin: 0px;
            padding: 0;
            bottom: 0;

            
          }

          .button_group
          {
            @include mq(450) {
              display: flex;
              flex-direction: row;
            }
          }

          button {
            background-color: $green300;
            border-radius: 9px;
            font-size: 18px;
            font-weight: 500;
            color: #f0f0f0;
            padding: 10px 20px;
            border-radius: 9px;
            font-size: 18px;
            font-weight: 500;
            margin: 0 5px;

            @include mq(450) {
              width: 99%;
              margin: 0;
              padding: 5px 20px;
              font-size: 15px;
              border: #00182a 1px solid;
            }
          }
          

          .white_button
          {
            background-color: $white;
            border-radius: 9px;
            font-size: 18px;
            font-weight: 500;
            color: $dark-blue-400;
            padding: 10px 20px;
            border-radius: 9px;
            font-size: 18px;
            font-weight: 500;
            margin: 0 75px;

            @include mq(450) {
              width: 99%;
              margin: 0;
              padding: 8px 20px;
              font-size: 16px;
              border: #00182a 1px solid;
            }

          }
          
        }

      }
      .feedback__step {
        .get__feedback {
          text-align: center;
          .snake__info {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 56px;
            .image {
              width: 566px;
              @include mq(768) {
                width: 80%;
                margin: 0 auto;
              }
              height: 316px;
              border-radius: 7px;
              overflow: hidden;
              margin-bottom: 22px;
              img {
                object-fit: cover;
                width: 100%;
                height: 100%;
              }
            }

            p {
              font-size: 22px;
              font-weight: 400;
              color: $dark-blue-400;
            }
          }
          .feedback__rating {
            p {
              font-size: 30px;
              font-weight: 400;
              color: $dark-blue-400;
              margin-bottom: 35px;
            }
            .feedback__item__box {
              .feedback {
                --normal: #eceaf3;
                --normal-shadow: #d9d8e3;
                --normal-mouth: #9795a4;
                --normal-eye: #595861;
                --active: #f8da69;
                --active-shadow: #f4b555;
                --active-mouth: #f05136;
                --active-eye: #313036;
                --active-tear: #76b5e7;
                --active-shadow-angry: #e94f1d;
                margin: 0;
                padding: 0;
                list-style: none;
                display: flex;
                justify-content: center;
                li {
                  position: relative;
                  border-radius: 50%;
                  background: var(--sb, var(--normal));
                  box-shadow: inset 3px -3px 4px var(--sh, var(--normal-shadow));
                  transition: background 0.4s, box-shadow 0.4s, transform 0.3s;
                  -webkit-tap-highlight-color: transparent;
                  &:not(:last-child) {
                    margin-right: 20px;
                  }
                  div {
                    width: 40px;
                    height: 40px;
                    position: relative;
                    transform: perspective(240px) translateZ(4px);
                    svg,
                    &:before,
                    &:after {
                      display: block;
                      position: absolute;
                      left: var(--l, 9px);
                      top: var(--t, 13px);
                      width: var(--w, 8px);
                      height: var(--h, 2px);
                      transform: rotate(var(--r, 0deg)) scale(var(--sc, 1))
                        translateZ(0);
                    }
                    svg {
                      fill: none;
                      stroke: var(--s);
                      stroke-width: 2px;
                      stroke-linecap: round;
                      stroke-linejoin: round;
                      transition: stroke 0.4s;
                      &.eye {
                        --s: var(--e, var(--normal-eye));
                        --t: 17px;
                        --w: 7px;
                        --h: 4px;
                        &.right {
                          --l: 23px;
                        }
                      }
                      &.mouth {
                        --s: var(--m, var(--normal-mouth));
                        --l: 11px;
                        --t: 23px;
                        --w: 18px;
                        --h: 7px;
                      }
                    }
                    &:before,
                    &:after {
                      content: "";
                      z-index: var(--zi, 1);
                      border-radius: var(--br, 1px);
                      background: var(--b, var(--e, var(--normal-eye)));
                      transition: background 0.4s;
                    }
                  }
                  &.angry {
                    --step-1-rx: -24deg;
                    --step-1-ry: 20deg;
                    --step-2-rx: -24deg;
                    --step-2-ry: -20deg;
                    div {
                      &:before {
                        --r: 20deg;
                      }
                      &:after {
                        --l: 23px;
                        --r: -20deg;
                      }
                      svg {
                        &.eye {
                          stroke-dasharray: 4.55;
                          stroke-dashoffset: 8.15;
                        }
                      }
                    }
                    &.active {
                      animation: angry 1s linear;
                      div {
                        &:before {
                          --middle-y: -2px;
                          --middle-r: 22deg;
                          animation: toggle 0.8s linear forwards;
                        }
                        &:after {
                          --middle-y: 1px;
                          --middle-r: -18deg;
                          animation: toggle 0.8s linear forwards;
                        }
                      }
                    }
                  }
                  &.sad {
                    --step-1-rx: 20deg;
                    --step-1-ry: -12deg;
                    --step-2-rx: -18deg;
                    --step-2-ry: 14deg;
                    div {
                      &:before,
                      &:after {
                        --b: var(--active-tear);
                        --sc: 0;
                        --w: 5px;
                        --h: 5px;
                        --t: 15px;
                        --br: 50%;
                      }
                      &:after {
                        --l: 25px;
                      }
                      svg {
                        &.eye {
                          --t: 16px;
                        }
                        &.mouth {
                          --t: 24px;
                          stroke-dasharray: 9.5;
                          stroke-dashoffset: 33.25;
                        }
                      }
                    }
                    &.active {
                      div {
                        &:before,
                        &:after {
                          animation: tear 0.6s linear forwards;
                        }
                      }
                    }
                  }
                  &.ok {
                    --step-1-rx: 4deg;
                    --step-1-ry: -22deg;
                    --step-1-rz: 6deg;
                    --step-2-rx: 4deg;
                    --step-2-ry: 22deg;
                    --step-2-rz: -6deg;
                    div {
                      &:before {
                        --l: 12px;
                        --t: 17px;
                        --h: 4px;
                        --w: 4px;
                        --br: 50%;
                        box-shadow: 12px 0 0 var(--e, var(--normal-eye));
                      }
                      &:after {
                        --l: 13px;
                        --t: 26px;
                        --w: 14px;
                        --h: 2px;
                        --br: 1px;
                        --b: var(--m, var(--normal-mouth));
                      }
                    }
                    &.active {
                      div {
                        &:before {
                          --middle-s-y: 0.35;
                          animation: toggle 0.2s linear forwards;
                        }
                        &:after {
                          --middle-s-x: 0.5;
                          animation: toggle 0.7s linear forwards;
                        }
                      }
                    }
                  }
                  &.good {
                    --step-1-rx: -14deg;
                    --step-1-rz: 10deg;
                    --step-2-rx: 10deg;
                    --step-2-rz: -8deg;
                    div {
                      &:before {
                        --b: var(--m, var(--normal-mouth));
                        --w: 5px;
                        --h: 5px;
                        --br: 50%;
                        --t: 22px;
                        --zi: 0;
                        opacity: 0.5;
                        box-shadow: 16px 0 0 var(--b);
                        filter: blur(2px);
                      }
                      &:after {
                        --sc: 0;
                      }
                      svg {
                        &.eye {
                          --t: 15px;
                          --sc: -1;
                          stroke-dasharray: 4.55;
                          stroke-dashoffset: 8.15;
                        }
                        &.mouth {
                          --t: 22px;
                          --sc: -1;
                          stroke-dasharray: 13.3;
                          stroke-dashoffset: 23.75;
                        }
                      }
                    }
                    &.active {
                      div {
                        svg {
                          &.mouth {
                            --middle-y: 1px;
                            --middle-s: -1;
                            animation: toggle 0.8s linear forwards;
                          }
                        }
                      }
                    }
                  }
                  &.happy {
                    div {
                      --step-1-rx: 18deg;
                      --step-1-ry: 24deg;
                      --step-2-rx: 18deg;
                      --step-2-ry: -24deg;
                      &:before {
                        --sc: 0;
                      }
                      &:after {
                        --b: var(--m, var(--normal-mouth));
                        --l: 11px;
                        --t: 23px;
                        --w: 18px;
                        --h: 8px;
                        --br: 0 0 8px 8px;
                      }
                      svg {
                        &.eye {
                          --t: 14px;
                          --sc: -1;
                        }
                      }
                    }
                    &.active {
                      div {
                        &:after {
                          --middle-s-x: 0.95;
                          --middle-s-y: 0.75;
                          animation: toggle 0.8s linear forwards;
                        }
                      }
                    }
                  }
                  &:not(.active) {
                    cursor: pointer;
                    &:active {
                      transform: scale(0.925);
                    }
                  }
                  &.active {
                    --sb: var(--active);
                    --sh: var(--active-shadow);
                    --m: var(--active-mouth);
                    --e: var(--active-eye);
                    div {
                      animation: shake 0.8s linear forwards;
                    }
                  }
                }
              }

              @keyframes shake {
                30% {
                  transform: perspective(240px) rotateX(var(--step-1-rx, 0deg))
                    rotateY(var(--step-1-ry, 0deg))
                    rotateZ(var(--step-1-rz, 0deg)) translateZ(10px);
                }
                60% {
                  transform: perspective(240px) rotateX(var(--step-2-rx, 0deg))
                    rotateY(var(--step-2-ry, 0deg))
                    rotateZ(var(--step-2-rz, 0deg)) translateZ(10px);
                }
                100% {
                  transform: perspective(240px) translateZ(4px);
                }
              }

              @keyframes tear {
                0% {
                  opacity: 0;
                  transform: translateY(-2px) scale(0) translateZ(0);
                }
                50% {
                  transform: translateY(12px) scale(0.6, 1.2) translateZ(0);
                }
                20%,
                80% {
                  opacity: 1;
                }
                100% {
                  opacity: 0;
                  transform: translateY(24px) translateX(4px) rotateZ(-30deg)
                    scale(0.7, 1.1) translateZ(0);
                }
              }

              @keyframes toggle {
                50% {
                  transform: translateY(var(--middle-y, 0))
                    scale(
                      var(--middle-s-x, var(--middle-s, 1)),
                      var(--middle-s-y, var(--middle-s, 1))
                    )
                    rotate(var(--middle-r, 0deg));
                }
              }

              @keyframes angry {
                40% {
                  background: var(--active);
                }
                45% {
                  box-shadow: inset 3px -3px 4px var(--active-shadow),
                    inset 0 8px 10px var(--active-shadow-angry);
                }
              }
            }
          }
          .form__wrapper {
            form {
             
              > .items {
                .item {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-end;
                  width: 100%;
                  margin-bottom: 30px;
                  label {
                    color: $dark-blue-220;
                    font-size: 22px;
                    font-weight: 400;
                    margin-bottom: 17px;
                    position: relative;
                    &.is__required {
                      &::after {
                        content: url("./../../images/icon/required.svg");
                        position: absolute;
                        top: 0;
                        right: -13px;
                      }
                    }
                  
                  }

                  @include mq(768) {
                    text-align: left;
                    font-size: 20px;
                  }


                  select
                  {
                    width: 50%;
                  }

                  input[type="text"] {
                    width: 100%;
                    background-color: $white;
                    border-radius: 5px;
                    border: 1px solid rgba($gray350, 0.37);
                    padding: 15px 0 14px 25px;
                  }
                  textarea {
                    width: 100%;
                    background-color: $white;
                    border-radius: 5px;
                    border: 1px solid rgba($gray350, 0.37);
                    padding: 15px 0 0 25px;
                    resize: none;
                    max-height: 197px;
                    height: 197px;
                  }
                }
              }
              .questions__box {
                > .items {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-end;

                  select
                  {
                    width: 80%;
                  }

                  @include mq(768) {
                    padding: 5px 5px;
                    text-align: left;
                    font-size: 20px;
                  }

                  > .item {
                    margin-bottom: 20px;
                    > label {
                      margin-bottom: 10px;
                      font-size: 20px;
                      font-weight: 400;
                      text-align: left;
                    }
                    
                    select
                    {
                      width: 80%;
                    }
                    .checks__items {
                      display: flex;
                      .item {
                        margin-right: 10px;
                        display: flex;
                        align-items: center;
                        label {
                          margin-right: 10px;
                          font-size: 15px;
                        }
                        input[type="radio"] {
                          width: 16px;
                          height: 16px;
                        }
                      }
                    }
                  }
                }
              }
              .button__wrapper {
                display: flex;
                justify-content: flex-start;
                margin-bottom: 30px;
                button[type="submit"] {
                  border-radius: 6px;
                  background-color: $green300;
                  border: 2px solid $green400;
                  color: $white;
                  font-size: 18;
                  font-weight: 500;
                  padding: 20px 50px;
                }
              }
            }
          }
        }
        .thank__you__feedback {
          text-align: center;
          .actions {
            h4 {
              font-size: 27px;
              font-weight: 500;
              color: $dark-blue-400;
              margin-bottom: 20px;
            }
            .two {
              width: 480px;
              @include mq(768) {
                width: 100%;
                flex-direction: column;
              }
              margin: auto;
              display: flex;
              justify-content: space-between;
              button {
                &:first-child {
                  width: calc(45% - 10px);
                }
                &:last-child {
                  width: calc(55% - 10px);
                }
                @include mq(768) {
                  width: 100% !important;
                  margin-bottom: 20px;
                }
                border-radius: 6px;
                padding: 20px 0;
                color: $white;
                font-size: 18px;
                font-weight: 500;
                &.blue__button {
                  background-color: $purple180;
                  border: 2px solid $purple200;
                }
                &.green__button {
                  background-color: $green300;
                  border: 2px solid $green50;
                }
              }
            }
          }
        }
        .checked-items
        {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          border-top: 1px $green300 solid ;
          border-bottom: 1px $green300 solid ;
          padding: 20px 0px;
          margin: 10px 0;

          @include mq(768)
          {
            flex-direction: column;
            padding: 20px 5px;
          }

          label
          {
            font-size: 20px;
            margin-bottom: 10px;
            font-weight: 400;
            width: 100%;
            
          }

          ul
          {
            width: 100%;
          }

           .checked-item
           {
             display: flex;
             flex-direction: column;
             justify-content: flex-end;
             align-items: center;
             width: 50%;
             text-align: right;

             @include mq(768)
             {
               width: 100%;
               
             }

              li{
                width: 100%;
                padding: 5px;

                label
                {
                  margin-left: 10px;
                  font-size: 15px;
                  width: auto;
                }
              }

           }

        }
      }
    }
  }
}


.my-modal {
  /*width: 1200px ;  
  max-width: 1200px;*/
} 
